/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";

import { RemoveCrewMemberDocumentsPositionsDialog } from "@/components/dialogs/RemoveCrewMemberDocumentsPositionsDialog ";
import { EditCalendarDocumentsDialog } from "@/components/dialogs/EditCalendarDocumentsDialog";

type DocumentModalProps = {
	show: boolean;
	onHide: () => void;
	document: any;
	userId: string | undefined;
}
const DocumentModal = ({
	show,
	onHide,
	document,
	userId,
}: DocumentModalProps) => {
	const { format } = useDateFormatter();
	
	const onCloseHandler = () => {
		onHide();
	}

	return (
		<Dialog open={show} onOpenChange={onCloseHandler} defaultOpen={false}>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Document</DialogTitle>
				</DialogHeader>

				<hr />

				{document && (
					<div className="space-y-2">
							<div className="flex items-center justify-start space-x-4 sm:justify-between">
								
								<div
									className="flex flex-col h-auto w-full flex-wrap "
								>
									<h4 className="text-left font-semibold sm:w-auto">
										Title: {document.title}
									</h4>

									<div className="text-sm flex flex-col sm:flex-nowrap sm:justify-center">
										<span className="hidden font-normal sm:inline-block">
											<span className="font-semibold">Created at:</span>{" "}
											{format(document.createdAt)}
										</span>
										<span className="font-normal">
											<span className="font-semibold">Updated at:</span>{" "}
											{format(document.updatedAt)}
										</span>
									</div>
								</div>
								
								<div className="flex items-center gap-1">
									<RemoveCrewMemberDocumentsPositionsDialog userId={userId} documentId={document.id} onSuccessCallback={onCloseHandler}/>
									<EditCalendarDocumentsDialog userId={userId} document={document} onSuccessCallback={onCloseHandler}/>
								</div>
							</div>

							{ document.end_date && (
								<div className="">
									<h3 className="font-semibold">Date Range:</h3>
									<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
										<span>{format(document.start_date)} &ndash; {format(document.end_date || document.start_date)}</span>
									</div>
								</div>
							)}
							
							<div className="">
								<h3 className="font-semibold">Documents:</h3>
								<ul className="list-inside list-decimal">
									{document?.files?.map((file: {name: string, uploadedBy: string, url: string}) => {
										return (
											<li
												key={file.name}
												className="my-1 flex items-center justify-between rounded-sm bg-gray-100 pl-2"
											>
												<div
													className="truncate text-sm flex items-center"
													title={`${file.name} &ndash; uploaded by ${file.uploadedBy}`}
												>
													<span className="truncate inline-block max:w-20 sm:max-w-40">{file.name}</span> &ndash; uploaded by {file.uploadedBy}
												</div>
												
												<div>
													<Button
														variant="outline"
														size="icon"
														asChild
														style={{ background: "#4CAF50", width: "28px", height: "28px" }}
													>
														<a className="flex items-center justify-center" href={file.url}>
															<Download color="#fff" size={20} />
														</a>
													</Button>
												</div>
											</li>
										);
									})}
								</ul>
							</div>
							<div className="">
								<h3 className="font-semibold">Description:</h3>
								<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
									<span>{document.description}</span>
								</div>
							</div>
					</div>
				)}
			</DialogContent>
		</Dialog>
	)
}

export { DocumentModal };
