import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
	useGetAdminArticleQuery,
	useGetAdminSectionsQuery,
} from "@/app/api/slices/admin/adminKnowledgeBaseApiSlice";
import {
	useGetVesselArticlesQuery,
	useGetVesselSectionsQuery,
} from "@/app/api/slices/manager/managerKnowledgeBaseApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { KnowledgeBaseReaderAccordion } from "@/components/accordions/KnowledgeBaseReaderAccordion";
import { KnowledgeBaseSectionsAccordion } from "@/components/accordions/KnowledgeBaseSectionsAccordion";
import { AddKnowledgeBaseSectionDialog } from "@/components/dialogs/AddKnowledgeBaseSectionDialog";
import { KnowledgeBaseArticlesTable } from "@/components/tables/KnowledgeBaseArticlesTable";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useJoyride } from "@/contexts/JoyrideContext";
import { joyride_redirect_7_index } from "@/hocs/JoyrideWrapper";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";

export const ManagerKnowledgeBasePage = () => {
	const vesselId = useCurrentVesselId();

	const { data: adminSectionsResponse, isLoading: isLoadingAdminSections } =
		useGetAdminSectionsQuery({ params: `?articles-status=public` });
	const {
		data: vesselSectionsResponse,
		isLoading: isLoadingSections,
		isFetching: isFetchingSections,
	} = useGetVesselSectionsQuery({
		vesselId,
	});
	const {
		data: vesselArticlesResponse,
		isLoading: isLoadingArticles,
		isFetching: isFetchingArticles,
	} = useGetVesselArticlesQuery({
		vesselId,
	});

	const [adminArticleToDisplayId, setAdminArticleToDisplayId] = useState<number | null>(null);
	useEffect(() => {
		if (
			adminSectionsResponse?.data?.length > 0 &&
			adminSectionsResponse?.data[0]?.articles.length > 0
		) {
			setAdminArticleToDisplayId(adminSectionsResponse.data[0]?.articles[0]?.id);
		}
	}, [adminSectionsResponse]);

	const { run, startJoyride, stepIndex } = useJoyride();
	useEffect(() => {
		startJoyride();
		if (
			!run &&
			!isLoadingSections &&
			!isFetchingSections &&
			!isLoadingArticles &&
			!isFetchingArticles &&
			!isLoadingAdminSections &&
			stepIndex === joyride_redirect_7_index
		) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [
		isLoadingSections,
		isFetchingSections,
		isLoadingArticles,
		isFetchingArticles,
		isLoadingAdminSections,
	]);

	const {
		data: adminArticleResponse,
		isLoading: isLoadingAdminArticle,
		isFetching: isFetchingAdminArticle,
	} = useGetAdminArticleQuery(
		{ articleId: adminArticleToDisplayId },
		{ skip: !adminArticleToDisplayId },
	);

	if (
		isLoadingSections ||
		isFetchingSections ||
		isLoadingArticles ||
		isFetchingArticles ||
		isLoadingAdminSections
	)
		return (
			<div id="knowledge-base-page">
				<InLayoutLoader />
			</div>
		);

	return (
		<Tabs id="knowledge-base-page" defaultValue="vessel">
			<TabsList id="knowledge-base-switcher">
				<TabsTrigger value="vessel">Vessel</TabsTrigger>
				<TabsTrigger value="admin">Admin</TabsTrigger>
			</TabsList>
			<TabsContent value="vessel">
				<div className="mt-4 flex flex-wrap gap-4">
					<WhiteBox id="knowledge-base-sections-box" className="w-full sm:w-80">
						<AddKnowledgeBaseSectionDialog />
						<KnowledgeBaseSectionsAccordion sections={vesselSectionsResponse.data} />
					</WhiteBox>
					<WhiteBox id="knowledge-base-articles-box" className="max-w-full grow">
						{vesselSectionsResponse?.data?.length > 0 ? (
							<Button className="mb-4 uppercase" asChild>
								<Link to={`/knowledge-base/add`}>Add article</Link>
							</Button>
						) : (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger className="cursor-default">
										<Button className="mb-4 uppercase" disabled>
											Add article
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										<p>Sections must be created before articles can be added.</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						)}
						<KnowledgeBaseArticlesTable data={vesselArticlesResponse.data} />
					</WhiteBox>
				</div>
			</TabsContent>
			<TabsContent value="admin">
				<div className="mt-4 flex flex-wrap gap-4">
					<WhiteBox className="w-full sm:w-80">
						<KnowledgeBaseReaderAccordion
							triggerArticleChange={setAdminArticleToDisplayId}
							visibleArticleId={adminArticleToDisplayId}
							sections={adminSectionsResponse.data}
						/>
					</WhiteBox>
					<WhiteBox className="max-w-full grow">
						{(isLoadingAdminArticle || isFetchingAdminArticle) && <h1>Loading...</h1>}
						{!isLoadingAdminArticle &&
							!isFetchingAdminArticle &&
							adminArticleResponse?.data?.content.length > 0 && (
								<div className="prose max-w-none">{parse(adminArticleResponse.data.content)}</div>
							)}
					</WhiteBox>
				</div>
			</TabsContent>
		</Tabs>
	);
};
