import { type ReactNode, type Dispatch, type SetStateAction, useState, useEffect, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { format as formatFns } from "date-fns";
import { CheckIcon } from "lucide-react";
import * as Checkbox from '@radix-ui/react-checkbox';

import { useToast } from "@/components/ui/use-toast";
import { CalendarDocumentsAddForm } from "@/components/forms/CalendarDocumentsAddForm/calendar_form";

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

import { handleServerErrors } from "@/utils/handleServerErrors";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Label } from "@/components/ui/label";

import { useGetAppConfigQuery } from "@/app/api/slices/appConfigApiSlice";

import * as z from "zod";

import { useEditCrewMemberRotationCalendarMutation } from "@/app/api/slices/rotationCalendarApiSlice";
import { useAddCrewMemberDocumentMutation, useAddCalendarDocumentMutation } from "@/app/api/slices/documentsApiSlice";
import { CalendarPicker } from "../ui/calendar-picker";

import { 
	useGetCalendarAdditionalDataQuery
} from "@/app/api/slices/commonCalendarApiSlice";

const formSchema = z.object({
    dayKind: z.string().trim().min(1, "Choose day kind"),
    showDocumentSection: z.boolean().optional(),
    note: z.string().max(255, "Note must be at most 255 characters long").optional(),

    title: z.string().optional(),
	description: z.string().optional(),
	files: z.any().optional(),
})
.refine(schema => {
    return schema.showDocumentSection ? schema.title && schema.title.length > 3 : true;
}, { message: "Title must contain at least 3 characters", path: ["title"] })
.refine(schema => {
    return schema.showDocumentSection ? schema.description && schema.description.length > 3 : true;
}, { message: "Description must contain at least 3 characters", path: ["description"] })
.refine(schema => {
    return schema.showDocumentSection ? (schema.files && schema.files?.length > 0) : true;
}, { message: "At least one file is required", path: ["files"] });


type FormType = z.infer<typeof formSchema>;

type Props = { 
    closeDayKindModalHandler: () => void,
    showDayKindModal: boolean,
    vessel_id: string | number | null | undefined,
    user_id: string | number | null | undefined,
    selectedContractId?: string | number | null | undefined,
    setDayKindStartDate: Dispatch<SetStateAction<Date | undefined>>,
    dayKindStartDate: Date | undefined,
    defaultDayKind: string | undefined | null,
    userName?: string | undefined | null,
    notesIds: string[] | number[] | undefined,
};

export const RotationCalendarDayKindDialog = ({
    closeDayKindModalHandler, 
    showDayKindModal, 
    vessel_id, 
    user_id, 
    selectedContractId, 
    dayKindStartDate, 
    setDayKindStartDate, 
    defaultDayKind, 
    userName="",
    notesIds
}: Props) => {
    const { data: configData } = useGetAppConfigQuery({});
    const day_types = configData?.data?.day_types

	const [dayKindEndDate, setDayKindEndDate] = useState<Date>();

    const [isSuccessUpload, setIsSuccessUpload] = useState<boolean | null>(null);
    const [isSuccessSave, setIsSuccessSave] = useState<boolean | null>(null);

    const { toast } = useToast();
    const [editCrewMemberRotationCalendar, { isLoading: isEditingCalendar }] = useEditCrewMemberRotationCalendarMutation();
    const [addCrewMemberDocumentsMutation, { isLoading: isUploadingFiles }] = useAddCrewMemberDocumentMutation();
    const [addCalendarDocumentMutation, { isLoading: isUploadingCalendarFiles }] = useAddCalendarDocumentMutation();
    

    const { data: calendarGeneralAdditionalData, isLoading: isLoadingCalendarGeneralAdditionalData, isFetching: isFetchingCalendarGeneralAdditionalData } = useGetCalendarAdditionalDataQuery({ 
        vesselId: vessel_id || "",
        documentsIds: [],
        notesIds: notesIds || []
    }, { skip: !vessel_id || !notesIds || (Array.isArray(notesIds) && notesIds.length === 0) });
    
    useEffect(() => {
        if(notesIds && Array.isArray(notesIds) && notesIds.length && calendarGeneralAdditionalData?.data?.notes) {
            const note = calendarGeneralAdditionalData.data.notes[notesIds[0]];
            
            if(note) {
                form.setValue("note", note.note);
            }
        }
    }, [calendarGeneralAdditionalData]);

    const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
            dayKind: "",
            showDocumentSection: false,
            title: "",
			description: "",
			files: [],
            note: ""
		},
	});
	const { handleSubmit, control, reset, setValue, resetField } = form;

    const showDocumentSectionWatch = form.watch("showDocumentSection"); 
    // FILE UPLOAD ------------------
	const files = form.watch("files");

	const handleDeleteFile = (index: number) => {
		if (files) {
			const filesArray = [...files];
			filesArray.splice(index, 1);

			setValue("files", filesArray as unknown as FileList);
		}
	};
    // ------------------------------

    const saveDayKindHandler = async ({dayKind, note}: {dayKind: string, note: string | undefined}) => {
		if(!dayKindStartDate || !vessel_id || !user_id) return;
		
		await editCrewMemberRotationCalendar({
			vessel_id: vessel_id,
			user_id: user_id,
			data: {
				id: selectedContractId || null,
				start_date: dayKindStartDate ? formatFns(dayKindStartDate, "y-MM-dd") : "", 
				end_date: dayKindEndDate ? formatFns(dayKindEndDate, "y-MM-dd") : "", 
				type: dayKind,
                note: note || null
			}
		})
		.unwrap()
		.then((_payload) => {
			toast({
				variant: "success",
				title: "Successfully updated calendar.",
			});
            setIsSuccessSave(true);
            onClose();
		})
		.catch((error) => {
            setIsSuccessUpload(false);
            handleServerErrors(error, toast)
        });
	};

    const handleAddCrewMemberDocuments = async (data: {
        title: string;
        description: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files?: any;
        start_date: string;
        end_date: string;
        contract_id: string | number | null | undefined;
    }) => {
		const formData = new FormData();

		for (const item in data) {
			// @ts-expect-error to-do: fix this
			const value = data[item];
			if (value instanceof FileList || value instanceof Array) {
				Array.from(value).forEach((file, index) => {
					formData.append(`${item}[${index}]`, file);
				});
			} else {
				formData.append(item, value);
			}
		}

        const uploadFc = ((contractId) => {
            if(contractId) {
                return addCrewMemberDocumentsMutation;
            }
            return addCalendarDocumentMutation;
        })(data.contract_id);

		await uploadFc({ vesselId: vessel_id, userId: user_id, data: formData })
			.unwrap()
			.then((_payload) => {
				setIsSuccessUpload(true);
				toast({
					variant: "success",
					title: "Successfully added document.",
				});
			})
			.catch((error) => {
                handleServerErrors(error, toast);
                setIsSuccessUpload(false);
            });
	};

    const onSubmit = (data: FormType) => {
        if(data.showDocumentSection) {
            if(!data.title || !data.description) return;

            handleAddCrewMemberDocuments({
                title: data.title,
                description: data.description,
                files: data.files,
				start_date: dayKindStartDate ? formatFns(dayKindStartDate, "y-MM-dd") : "", 
				end_date: dayKindEndDate ? formatFns(dayKindEndDate, "y-MM-dd") : "", 
                contract_id: selectedContractId
            });
        }

        saveDayKindHandler({dayKind: data.dayKind, note: data.note});
    }

    const showAddDocumentSectionHandler = (show: boolean) => {
        if(!show) {
            resetField("title");
            resetField("description");
            resetField("files");
        }
    };

    const onClose = () => {
        reset({
            dayKind: "",
            showDocumentSection: false,
            title: "",
            description: "",
            files: [],
            note: ""
        });
        setDayKindEndDate(undefined);
        closeDayKindModalHandler();

        setIsSuccessSave(null);
        setIsSuccessUpload(null);
    }

    useEffect(() => {
        if(isSuccessUpload && isSuccessSave) {
            onClose();
        }
    }, [isSuccessUpload, isSuccessSave]);

    useEffect(() => {
        if(showDayKindModal && defaultDayKind) {
            setValue("dayKind", defaultDayKind);
        }
    }, [showDayKindModal, setValue, defaultDayKind]);

    const areDatePickersDisabled = defaultDayKind ? ["change_day", "travel_day_change_day"].includes(defaultDayKind) : false;
    const possibleDayTypes = useMemo(() => {
        if(!day_types || !defaultDayKind) return null;
        const allDayTypes = Object.entries(day_types).map(([key, value]) => ({id: key, name: value}));
        
        if(defaultDayKind === "change_day" || defaultDayKind === "travel_day_change_day") {
            return allDayTypes.filter(dayType => ["change_day", "travel_day_change_day"].includes(dayType.id));
        }
        return allDayTypes.filter(dayType => !["change_day", "travel_day_change_day"].includes(dayType.id));
    }, [defaultDayKind, day_types]);

    const isLoading = isEditingCalendar || isUploadingFiles || isUploadingCalendarFiles;

    return (
        <Dialog open={showDayKindModal} onOpenChange={onClose} defaultOpen={false}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle>Choose day kind</DialogTitle>
                    { userName && (<p className="italic text-sm">{userName}</p>)}
                </DialogHeader>

                <div>
                    <Form {...form}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid gap-2 grid-cols-2">
                                <div className="">
                                    <Label className="whitespace-nowrap">Start Date</Label>
                                    <CalendarPicker 
                                        placeholder="Select start date"
                                        disabled={isEditingCalendar || isUploadingFiles || areDatePickersDisabled || isUploadingCalendarFiles} 
                                        onSelect={setDayKindStartDate} 
                                        selected={dayKindStartDate}
                                        defaultMonth={dayKindStartDate}
                                    />
                                </div>
                                <div className="">
                                    <Label className="whitespace-nowrap">End Date (optional)</Label>
                                    <CalendarPicker 
                                        placeholder="Select end date"
                                        disabled={isEditingCalendar || isUploadingFiles || areDatePickersDisabled || isUploadingCalendarFiles} 
                                        onSelect={setDayKindEndDate} 
                                        selected={dayKindEndDate}
                                        fromDate={dayKindStartDate} 
                                        defaultMonth={dayKindStartDate}
                                    />
                                </div>
                                <div className="col-span-2 mt-2">
                                    <FormField
                                        control={control}
                                        name="dayKind"
                                        render={({ field }) => (
                                            <FormItem>
                                                <div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
                                                    <div className="flex items-center justify-start gap-2">
                                                        <FormLabel className="whitespace-nowrap">Day Kind</FormLabel>
                                                        <FormMessage />
                                                    </div>
                                                    <FormControl>
                                                        <Select onValueChange={field.onChange} defaultValue={field.value} disabled={isLoading}>
                                                            <SelectTrigger>
                                                                <SelectValue />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectGroup>
                                                                    { possibleDayTypes?.map(({id, name}) => (
                                                                        <SelectItem key={id} value={id}>{name as ReactNode}</SelectItem>
                                                                    ))}
                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className="col-span-2 mt-2">

                                    <FormField
                                        control={control}
                                        name="note"
                                        render={({ field }) => (
                                            <FormItem>
                                                <div className="grid grid-cols-1 items-start gap-3 sm:grid-cols-3">
                                                    <div className="flex items-center justify-start gap-2 sm:col-span-1">
                                                        <FormLabel className="whitespace-nowrap">Note</FormLabel>
                                                        <FormMessage />
                                                    </div>
                                                    <FormControl className="sm:col-span-2 relative">
                                                        <div>
                                                            <Textarea {...field} disabled={isLoading}/>

                                                            {(isLoadingCalendarGeneralAdditionalData || isFetchingCalendarGeneralAdditionalData) && (
                                                                <div className="absolute flex justify-center items-center top-0 left-0 right-0 bottom-0">
                                                                    <ButtonLoader isLoading={true}/>
                                                                </div>
                                                            )}
                                                            
                                                        </div>
                                                    </FormControl>
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>

                            <hr className="my-4" />
                            <div className="mb-3 flex justify-between">
                                <h3 className="text-lg font-semibold leading-none tracking-tight">Add Documents</h3>
                                <FormField
                                    control={control}
                                    name="showDocumentSection"
                                    render={({ field }) => (
                                        <FormItem>
                                            <Checkbox.Root className="w-10 h-10 flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
                                                onCheckedChange={(value) => {
                                                    field.onChange(value);
                                                    if(!value) {
                                                        showAddDocumentSectionHandler(value);
                                                    }
                                                }}
                                                checked={field.value}
                                                disabled={isLoading}
                                            >
                                                <Checkbox.Indicator className="CheckboxIndicator">
                                                    <CheckIcon />
                                                </Checkbox.Indicator>
                                            </Checkbox.Root>
                                        </FormItem>
                                    )}
                                />
                            </div>

                            {showDocumentSectionWatch && (
                                <CalendarDocumentsAddForm control={control} handleDeleteFile={handleDeleteFile} isUploading={isLoading}/>
                            )}

                            <hr className="my-4" />
                            <div className="grid gap-2 grid-cols-2">
                                <Button type="button" disabled={isLoading} variant="secondary" className="mt-3 w-full" onClick={onClose}>
                                    Cancel
                                </Button>
                                
                                <Button type="submit" disabled={isLoading} className="mt-3 w-full">
                                    <ButtonLoader isLoading={isLoading}/>Save
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}
