import { X } from "lucide-react";
import { type MouseEventHandler } from "react";
import { Link } from "react-router-dom";

import { LogoutButton } from "@/components/LogoutButton";
import { Button } from "@/components/ui/button";
import { adminMenu } from "@/data/adminMenu";
import { managerMenu } from "@/data/managerMenu";
import { useAuth } from "@/hooks/useAuth";
import { cn } from "@/lib/utils";

type Props = {
	className?: string;
	isExpanded: boolean;
	onExpandedChange: MouseEventHandler<HTMLButtonElement>;
};

export const SideMenu = ({ className, isExpanded, onExpandedChange }: Props) => {
	const auth = useAuth();
	const isAdmin = auth?.user?.role.name === "admin";
	const menu = isAdmin ? adminMenu : managerMenu;

	return (
		<div className={cn("relative pb-10 sm:block", className)}>
			<div className="hidden w-16 sm:block"></div>
			<div
				id="rotat-menu"
				className={`absolute left-0 top-0 z-40 h-full overflow-hidden bg-white py-2 transition-all duration-500 ${
					isExpanded ? "w-60" : "w-0 sm:w-16"
				}`}
			>
				<div className="flex h-full flex-col justify-start space-y-1">
					<div>
						<div className="flex h-10 items-center justify-end px-2">
							<Button
								className={`${isExpanded ? "" : "hidden"} `}
								variant="ghost"
								size="icon"
								onClick={onExpandedChange}
							>
								<X />
							</Button>
						</div>
						{menu.map((item) => {
							return (
								<Button
									asChild
									key={item.title}
									variant="ghost"
									className={`flex items-center justify-start gap-3`}
									onClick={isExpanded ? onExpandedChange : undefined}
								>
									<Link to={item.link} id={item.id}>
										<div className="ml-1">{item.icon}</div>
										<div className="flex flex-col items-start overflow-hidden transition-all duration-300">
											{item.title}
											{item?.subtitle && (
												<>
													<span className="text-xs">{item.subtitle}</span>
												</>
											)}
										</div>
									</Link>
								</Button>
							);
						})}
					</div>
					<LogoutButton />
				</div>
			</div>
		</div>
	);
};
