import { ChevronsUpDown, PersonStanding } from "lucide-react";

import { DepartmentStatistics } from "@/components/DepartmentStatistics";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { useJoyride } from "@/contexts/JoyrideContext";
import { type StringOpenStates } from "@/types/AccordionOpenStates";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";

type Props = {
	openStates: StringOpenStates;
	toggleOpenState: (sectionId: string) => void;
	departments: {
		id: number;
		name: string;
		positionsStats: { name: string; onboard: number; offboard: number }[];
	}[];
	summary: {
		offboard: number;
		onboard: number;
	}
};

export const VesselDepartmentsStatisticsAccordion = ({
	openStates,
	toggleOpenState,
	departments,
	summary
}: Props) => {
	const { setStepIndex } = useJoyride();

	return (
		<div className="flex flex-col gap-3">
			{ (summary || departments) && (
				<div className="flex justify-end px-3 text-sm">
					<div className="flex">
						<HoverCard>
							<HoverCardTrigger>
								<div className={`inline-block rounded-sm bg-green-600 w-4 h-4`}>
									<PersonStanding color="#fff" size={16}/>
								</div>
							</HoverCardTrigger>
							<HoverCardContent className="ms-1">Signed On</HoverCardContent>
						</HoverCard>

						<div className="px-0.5">/</div>

						<HoverCard>
							<HoverCardTrigger>
								<div className={`inline-block rounded-sm bg-red-600 w-4 h-4`}>
									<PersonStanding color="#fff" size={16}/>
								</div>
							</HoverCardTrigger>
							<HoverCardContent className="ms-1">Signed Off</HoverCardContent>
						</HoverCard>
					</div>
				</div>
			)}

			{ summary && (
				<>

					<div className="flex justify-between px-3 text-sm">
						<span>Total</span>
						<span className="vessel-departments-statistics-value">
							{summary.onboard || "-"}/{summary.offboard || "-"}
						</span>
					</div>
					<hr />
				</>
			)}

			{departments.map((department, index) => {
				return (
					<Collapsible
						key={department.id}
						open={openStates[`dep-${index}`] || false}
						onOpenChange={() => toggleOpenState(`dep-${index}`)}
						className="space-y-2"
					>
						<div className="flex items-center justify-between space-x-4">
							<CollapsibleTrigger asChild>
								<Button
									onClick={() => setStepIndex((prev: number) => prev + 1)}
									variant="ghost"
									size="sm"
									className="vessel-departments-statistics-trigger flex w-full items-center justify-between"
								>
									<h4 className="text-sm font-semibold">{department.name}</h4>
									<ChevronsUpDown className="h-4 w-4" />
									<span className="sr-only">Toggle</span>
								</Button>
							</CollapsibleTrigger>
						</div>
						<CollapsibleContent className="px-3">
							<DepartmentStatistics positionsStats={department.positionsStats} />
						</CollapsibleContent>
					</Collapsible>
				);
			})}
		</div>
	);
};
