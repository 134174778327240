export const myVesselSteps = [
	// 8 - MY VESSEL
	{
		target: "#vessel-crew-statistics",
		content: (
			<div>
				<p>You will find here basic statistics about the amount of the crew on your boat</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 9 - MY VESSEL
	{
		target: "#vessel-departments-statistics",
		content: (
			<div>
				<p>Statistics divided by departments for the better overview</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 10 - MY VESSEL
	// {
	// 	target: ".vessel-departments-statistics-trigger",
	// 	content: (
	// 		<div>
	// 			<p>Click the spotlight area to open department statistics</p>
	// 		</div>
	// 	),
	// 	disableBeacon: true,
	// 	disableOverlayClose: true,
	// 	hideCloseButton: true,
	// 	hideFooter: true,
	// 	spotlightClicks: true,
	// 	placement: "auto",
	// 	styles: {
	// 		tooltipContent: {
	// 			padding: "10px",
	// 		},
	// 		spotlight: {
	// 			boxShadow: "0px 0px 20px 10px rgba(251, 133, 0, 0.4)",
	// 		},
	// 	},
	// },
	// 11 - MY VESSEL
	{
		target: ".vessel-departments-statistics-value",
		content: (
			<div>
				<p>Number on the left is the amount of crew onboard, number on the right crew on leave</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},

	// 12 - MY VESSEL
	{
		target: "#vessel-departments-statistics-big-box",
		content: (
			<div>
				<p>Stats with more details divided into departments</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 12 - MY VESSEL
	{
		target: "#my-vessel-datepicker",
		content: (
			<div>
				<p>Select specific date to see Arrival/Departure dates.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 13 - MY VESSEL
	{
		target: ".vessel-departments-statistics-big-box-position",
		content: (
			<div>
				<p>You can click the position name to navigate to positions calendar</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 14 - MY VESSEL
	{
		target: ".vessel-departments-statistics-big-box-member",
		content: (
			<div>
				<p>You can click the crew member name to navigate to his rotation calendar</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 15 - MY VESSEL
	{
		target: ".vessel-departments-statistics-big-box-leave-balance",
		content: (
			<div>
				<p>Leave balance calculated for the last day of selected month.</p>
				<p>
					Data driven leave balance. For calculations, we take into account days onboard and days
					offboard and rotation type.
				</p>
				<p>It is always live and adapts to selected date.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
];
