import { useState, useEffect } from "react";
import { useParams  } from "react-router-dom";

import { WhiteBox } from "@/components/WhiteBox";
import { Calendar as RotationCalendar } from "@/components/rotation-calendar/Calendar";
import { format as formatFns } from "date-fns";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle
} from "@/components/ui/dialog";
import { RotationCalendarDayKindDialog } from "@/components/dialogs/RotationCalendarDayKindDialog";

import { 
	useGetCrewMemberRotationSettingsQuery, 
	useGetCrewMemberContractsListQuery,
	useGetCrewMemberRotationCalendarDataQuery,
	useCreateCrewMemberContractMutation,
} from "@/app/api/slices/rotationCalendarApiSlice";
import { 
	useGetAppConfigQuery
} from "@/app/api/slices/appConfigApiSlice";

import { Controls } from "@/components/rotation-calendar/Controls";
import { type RotationCalendarData } from "@/types/RotationCalendarInterfaces";
import { useGetCrewMemberDocumentsRelatedWithCalendarQuery } from "@/app/api/slices/documentsApiSlice";
import { useGetCrewMemberNotesRelatedWithCalendarQuery } from "@/app/api/slices/notesApiSlice";

import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { useDateFormatter } from "@/hooks/useDateFormatter";

export const RotationCalendarPage = () => {
	const { data: configData } = useGetAppConfigQuery({});
	const { format } = useDateFormatter();
	const params = useParams();
	const vessel_id = useCurrentVesselId();
	const { user_id } = params;

	const [perPage, setPerPage] = useState(5);
    const [page, setPage] = useState<number | undefined>();
	const [showDayKindModal, setShowDayKindModal] = useState(false);
	const [dayKindStartDate, setDayKindStartDate] = useState<Date>();
	const [defaultDayKind, setDefaultDayKind] = useState<string | undefined | null>();

	const [selectedContract, setSelectedContract] = useState<RotationCalendarData | undefined>();

	const [selectedContractId, setSelectedContractId] = useState<number | string | undefined | null>();

	const [contractStartDate, setContractStartDate] = useState<string | undefined | null>();
	const [contractEndDate, setContractEndDate] = useState<string | undefined | null>();

	const [showSettingsMobile, setShowSettingsMobile] = useState(false);

	const {
		data: rotationCalendarSettings,
		isLoading: isLoadingSettings,
	} = useGetCrewMemberRotationSettingsQuery({ vessel_id: vessel_id || 0, user_id: user_id || 0 }, { skip: !vessel_id || !user_id });

	const { isLoading: isLoadingContracts } = useGetCrewMemberContractsListQuery({ vessel_id: vessel_id || 0, user_id: user_id || 0 }, { skip: !vessel_id || !user_id });

	const { data: documentsData, isLoading: _isLoadingDocumentsData } = useGetCrewMemberDocumentsRelatedWithCalendarQuery({ 
		vesselId: vessel_id, userId: user_id, startDate: contractStartDate, endDate: contractEndDate, contractId: selectedContractId}, 
		{ skip: !vessel_id || !user_id || !contractStartDate || !contractEndDate || !selectedContractId}
	);

	const { data: notesData, isLoading: _isLoadingNotesData } = useGetCrewMemberNotesRelatedWithCalendarQuery({ 
		vesselId: vessel_id, usersIds: user_id, startDate: contractStartDate, endDate: contractEndDate}, 
		{ skip: !vessel_id || !user_id || !contractStartDate || !contractEndDate}
	);

	// const notesData = {
	// 	data: {
	// 		"notes": {
	// 			"8": { "note": "This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note." },
	// 			"9": { "note": "...dolor sit amet..." },
	// 			"10": { "note": "Test..." }
	// 		},
	// 		"dates": {
	// 			"2024-11-01": [8, 9],
	// 			"2024-11-12": [10]
	// 		}
	// 	}
	// }
	

	const {
		data: calendarData,
		isLoading: isLoadingCalendar,
		isFetching: isFetchingCalendar,
	} = useGetCrewMemberRotationCalendarDataQuery({ vessel_id: vessel_id || 0, user_id: user_id || 0, page, rotations_count: perPage, contract_id: selectedContractId || 0 }, { skip: !vessel_id || !user_id || !page || !perPage || !selectedContractId});

	const maxPages = calendarData?.data?.total_pages || 1;

	const determineWhichDateIs = ({dateA, dateB, mode}: {dateA: string, dateB: string, mode: 'theNewest' | 'theOldest'}) => {
		const d_dateA = new Date(dateA);
		const d_dateB = new Date(dateB);

		if(mode === 'theNewest') {
			return d_dateA > d_dateB ? dateA : dateB;
		}
		return d_dateA > d_dateB ? dateB : dateA;
	};

	useEffect(() => {
		if(calendarData){
			const firstRotation = calendarData.data?.rotations[0];
			const lastRotation = calendarData.data?.rotations[calendarData.data?.rotations.length - 1];

			if(firstRotation && lastRotation) {
				const firstDate = determineWhichDateIs({
					dateA: firstRotation.onboard.start_date, 
					dateB: firstRotation.offboard.start_date, 
					mode: 'theOldest'
				});

				const lastDate = determineWhichDateIs({
					dateA: lastRotation.onboard.end_date, 
					dateB: lastRotation.offboard.end_date, 
					mode: 'theNewest'
				});

				setContractStartDate(firstDate);
				setContractEndDate(lastDate);
			};
		}
	}, [calendarData]);


	const [_createCrewMemberRotation, { isLoading: isCreatingContract }] = useCreateCrewMemberContractMutation();

	const perPageHandler = (value: string) => {
		if(!value || !vessel_id || !user_id) return;
        const rowsCount = value;

        setPerPage(Number(rowsCount));

        const currentPage = 1;
        setPage(currentPage);
    }

    const pageHandler = ({dir, currentPage}: {dir: string, currentPage: number | undefined}) => {
        if(dir !== "prev" && dir !== "next") throw new Error("Wrong argument!");
		if(currentPage === undefined) return;

        let nextPage = currentPage;
        if(dir === "prev" && currentPage > 1) {
            nextPage -= 1;
            setPage(nextPage);
        } else if(dir === "next" && currentPage < maxPages) {
            nextPage += 1;
            setPage(nextPage);
        }
    };

	// const dayNotesIds 

	const showDayKindModalHandler = ({startDate, dayKind} : {startDate: Date, dayKind: string}) => {
		setDayKindStartDate(startDate);
		setDefaultDayKind(dayKind);
		setShowDayKindModal(true);
	}

	const closeDayKindModalHandler = () => {
		setShowDayKindModal(false);
		setDayKindStartDate(undefined);
		setDefaultDayKind(undefined);
	}

	const getNotesIds = (date: undefined | Date) => {
		if(!notesData || !date) return;

		const formattedDate = formatFns(date, "yyyy-MM-dd");
		return notesData?.data?.dates[formattedDate] || [];
	};

	return (
		<div id="rotation-calendar-page" className="flex flex-col lg:flex-row">
			<RotationCalendarDayKindDialog 
				closeDayKindModalHandler={closeDayKindModalHandler} 
				showDayKindModal={showDayKindModal} 
				vessel_id={vessel_id || ""} 
				user_id={user_id || ""} 
				selectedContractId={selectedContractId} 
				dayKindStartDate={dayKindStartDate} 
				setDayKindStartDate={setDayKindStartDate}
				defaultDayKind={defaultDayKind}
				notesIds={getNotesIds(dayKindStartDate)}
			/>

			<Dialog open={showSettingsMobile} onOpenChange={() => setShowSettingsMobile(false)} defaultOpen={false}>
				<DialogContent style={{height: "calc(95vh)"}}>
					<DialogHeader>
						<DialogTitle>Rotation settings</DialogTitle>
					</DialogHeader>

					<div className="overflow-y-auto">
						<div>
							<Controls 
								isLoadingSettings={isLoadingSettings}
								isLoadingCalendar={isLoadingCalendar}
								isFetchingCalendar={isFetchingCalendar}
								rotationCalendarSettings={rotationCalendarSettings}
								configData={configData}
								isMobile={true}
								setPage={setPage}
								selectedContract={selectedContract}
								setSelectedContract={setSelectedContract}
								setSelectedContractId={setSelectedContractId}
								selectedContractId={selectedContractId}
							/>
						</div>
					</div>

				</DialogContent>
			</Dialog>

			<div className="block lg:hidden">
				<div className="cursor-pointer" onClick={() => setShowSettingsMobile(true)}>
					<WhiteBox className="lg:w-80 lg:me-4 mb-4 flex justify-between flex-wrap">
						<div className="text-xs me-3">
							<span className="me-1 font-bold">{rotationCalendarSettings?.data?.user?.name ?? "-"}</span>
							<span>({rotationCalendarSettings?.data?.user?.position?.name ?? "-"})</span>
						</div>

						<div className="text-xs flex">
							<span className="me-1 font-bold">{selectedContract?.rotation}</span>
							<span className="text-xs">&#40;{format(selectedContract?.start_date ?? new Date())} to {format(selectedContract?.end_date ?? new Date())}&#41;</span>
						</div>
					</WhiteBox>
				</div>
			</div>

			<section className="hidden lg:block lg:w-80 lg:me-4">
				<Controls 
					isLoadingSettings={isLoadingSettings}
					isLoadingCalendar={isLoadingCalendar}
					isFetchingCalendar={isFetchingCalendar}
					rotationCalendarSettings={rotationCalendarSettings}
					configData={configData}
					isMobile={false}
					setPage={setPage}
					selectedContract={selectedContract}
					setSelectedContract={setSelectedContract}
					setSelectedContractId={setSelectedContractId}
					selectedContractId={selectedContractId}
				/>
			</section>
			
			<RotationCalendar 
				page={page}
				perPage={perPage}
				maxPages={maxPages}
				pageHandler={pageHandler}
				perPageHandler={perPageHandler}
				calendarData={calendarData?.data?.rotations}
				showDayKindModalHandler={showDayKindModalHandler}
				rotation={selectedContract?.rotation}
				isPartner={selectedContract?.isPartner}
				crewMemberName={rotationCalendarSettings?.data?.user?.name}
				partnerId={selectedContract?.rotation_partner?.id}
				documents={documentsData?.data}
				rotationPeriodType={calendarData?.data?.rotationPeriodType}
				positionId={rotationCalendarSettings?.data?.user?.position?.id}
				contractId={selectedContractId}
				isLoadingCalendar={isLoadingCalendar}
				isFetchingCalendar={isFetchingCalendar}
				isLoadingContracts={isLoadingContracts}
				isCreatingContract={isCreatingContract}

				notes={notesData?.data}
			/>
		</div>
	);
};
