import { apiSlice } from "@/app/api/apiSlice";

// tags: ShipCalendarDates, ShipCalendarData
export const shipCalendarApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAvailableDates: builder.query({
			query: ({
				vessel_id,
			}: {
				vessel_id: undefined | number | string;
			}) => `/api/vessels/${vessel_id}/ship-calendar/dates`,
			providesTags: (_result, _error, { vessel_id }) => [
				{ type: "ShipCalendarDates", id: `${vessel_id}` },
			],
		}),
		getCalendarData: builder.query({
			query: ({
				vessel_id,
				positionIds,
				onoffview,
				hods,
				start_date,
				end_date
			}: {
				vessel_id: undefined | number | string;
				positionIds: number[] | string[];
				onoffview: 'all' | 'on' | 'off';
				hods: boolean;
				start_date: string;
				end_date: string;
			}) => `/api/vessels/${vessel_id}/ship-calendar?positions=[${positionIds}]&onoffview=${onoffview}&hods=${hods}&start_date=${start_date}&end_date=${end_date}`,
			providesTags: (_result, _error, { vessel_id, positionIds, onoffview, hods, start_date, end_date }) => [
				{ type: "ShipCalendarData", id: `${vessel_id}&positions=[${positionIds}]&onoffview=${onoffview}&hods=${hods}&start_date=${start_date}&end_date=${end_date}` },
			],
		}),
		getStatisticsByDepartment: builder.query({
			query: ({
				vesselId,
				positionIds,
				date
			}: {
				vesselId: undefined | number | string;
				positionIds: number[] | string[] | undefined | null;
				date: string | undefined | null;
			}) => `/api/vessels/${vesselId}/ship-calendar/statistics?positions=${positionIds}&date=${date}`,
			providesTags: (_result, _error, { vesselId, positionIds, date}) => [
				{ type: "ShipCalendarDepartmentStatistics", id: `${vesselId}${positionIds}${date}` },
			],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetAvailableDatesQuery,
	useGetCalendarDataQuery,
	useGetStatisticsByDepartmentQuery,
} = shipCalendarApiSlice;
