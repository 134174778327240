/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState, useMemo, useEffect, useLayoutEffect, useRef } from "react";
import { WhiteBox } from "@/components/WhiteBox";
import { cn } from "@/lib/utils";
import { isToday, format as formatFns, getMonth, getYear, differenceInDays, differenceInMonths, addDays, getDate } from "date-fns";
import { ButtonLoader } from "@/components/ButtonLoader";
import { DAY_TYPE } from "@/utils/calendarUtils";
import RotatLogo from "@/assets/logo-small.svg";
import { useGetAvailableDatesQuery, useGetCalendarDataQuery } from "@/app/api/slices/shipCalendarApiSlice";
import { RotationCalendarDayKindDialog } from "../dialogs/RotationCalendarDayKindDialog";
import { SelectYear } from "./SelectYear";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { useGetDepartmentsAndPositionsQuery } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import { Filters } from "./Filters";
import { AdaptModal } from "@/components/calendar/Modals/AdaptModal";
import { 
	type Group,
	type CrewInterface,
	type PositionInterface,
	type DepartmentInterface,
	type OnOffView
} from "./types";
import { ControlPanel } from "./ControlPanel";
import { CalendarRowLabel } from "./CalendarRowLabel";
import { LabelsSkeletonLoader } from "./LabelsSkeletonLoader";
import { CalendarCell } from "./CalendarCell";
import { CalendarCellsSkeletonLoader } from "./CalendarCellsSkeletonLoader";
import { useJoyride } from "@/contexts/JoyrideContext";
import { shipCalendarSteps } from "@/data/joyride/ship-calendar";
import { joyride_redirect_2_index } from "@/hocs/JoyrideWrapper";

import { 
	useCheckCalendarAdditionalDataQuery,
} from "@/app/api/slices/commonCalendarApiSlice";

import { DocumentModal } from "./DocumentModal";
import { months, calendarSizesClasses } from "./settings";
import { formatDateToStandarizedForm } from "@/utils/calendarUtils";

type CalendarProps = {
	selectedDate: string | null | undefined;
	selectedCrewMemberId: string | number | undefined;
	setSelectedCrewMemberId: (date: number | string | undefined) => void;
	onSetSelectedDate: (date: string | null | undefined) => void;
    onSetSelectedPositionIds: React.Dispatch<React.SetStateAction<number[] | string[] | null | undefined>>;
};

const index_of_last_filter_modal_step = shipCalendarSteps.findIndex(
	(step) => step.target === "#ship-calendar-filters-modal",
);
const index_of_last_datepicker_modal_step = shipCalendarSteps.findIndex(
	(step) => step.target === "#ship-calendar-datepicker-modal",
);
const close_filter_modal_index = joyride_redirect_2_index + index_of_last_filter_modal_step + 1;
const close_datepicker_modal_index = joyride_redirect_2_index + index_of_last_datepicker_modal_step + 1;

export const Calendar = ({
	selectedCrewMemberId,
	setSelectedCrewMemberId,

	selectedDate,
	onSetSelectedDate,
	onSetSelectedPositionIds
}: CalendarProps) => {
	const calendarContainerRef = useRef<HTMLDivElement>(null);
	const calendarContainerCrewLabelsRef = useRef<HTMLDivElement>(null);
	const calendarDaysContainerRef = useRef<HTMLDivElement>(null);

	const currentScrollPosition = useRef({
		scrollLeft: 0,
		scrollWidth: 0,
		clientWidth: 0,
	})

	const { format } = useDateFormatter();
	const vessel_id = useCurrentVesselId();
	const { stepIndex, setStepIndex } = useJoyride();

	const [showYearDialog, setShowYearDialog] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [selectedGroups, setSelectedGroups] = useState<string | undefined | Group[]>('all');
	const [onOffView, setOnOffView] = useState<OnOffView>('all');

	const [calendarZoomLevel, setCalendarZoomLevel] = useState(1);

	const [startDate, setStartDate] = useState<string | undefined | null>();
	const [endDate, setEndDate] = useState<string | undefined | null>();
	const [selectedDepartmentsAndPositions, setSelectedDepartmentsAndPositions] = useState<Group[]>([]);
	const [selectedPositionsIds, setSelectedPositionsIds] = useState<number[] | string[]>([]);
	
	const { data: departmentsAndPositionsData, isLoading: isLoadingDepartments } = useGetDepartmentsAndPositionsQuery({ vesselId: vessel_id }, { skip: !vessel_id });
	const { isLoading: isLoadingAvailableDates } = useGetAvailableDatesQuery({ vessel_id: vessel_id });
	const { data: calendarData, isLoading: isLoadingCalendarData, isFetching: isFetchingCalendarData } = useGetCalendarDataQuery({ 
		vessel_id,
		positionIds: selectedPositionsIds,
		onoffview: onOffView,
		hods: false,
		start_date: startDate,
		end_date: endDate
	}, { skip: !vessel_id || !startDate || !endDate || !selectedPositionsIds.length });

	const { data: calendarGeneralAdditionalData, isLoading: isLoadingCalendarGeneralAdditionalData, isFetching: isFetchingCalendarGeneralAdditionalData } = useCheckCalendarAdditionalDataQuery({ 
		vesselId: vessel_id || "",
		positionsIds: selectedPositionsIds,
		startDate: startDate || "",
		endDate: endDate || ""
	}, { skip: !vessel_id || !startDate || !endDate || !selectedPositionsIds.length });
	
	useEffect(() => {
		if (stepIndex === close_filter_modal_index) {
			setShowFilters(false);
		}
		if (stepIndex === close_datepicker_modal_index) {
			setShowYearDialog(false);
		}
	}, [stepIndex]);

	const showFiltersDialogHandler = () => {
		setShowFilters(true);
		setTimeout(() => {
			setStepIndex((prev) => prev + 1);
		}, 400);
	}

	const hideFiltersDialogHandler = () => {
		setShowFilters(false);
	}

	const showYearDialogHandler = () => {
		setShowYearDialog(true);
		setTimeout(() => {
			setStepIndex((prev) => prev + 1);
		}, 400);
	}

	const hideYearDialogHandler = () => {
		setShowYearDialog(false);
	}

	const selectMonthDayHandler = (date: null | string | Date) => {
		onSetSelectedDate(date);
	};

	const setPositionIds = (positionIds: number[] | string[]) => {
		onSetSelectedPositionIds(positionIds);
		setSelectedPositionsIds(positionIds);
		selectMonthDayHandler(null); // Reset selected month day
	}

	const selectedDepartmentsAndPositionsHandler = (selected: Group[]) => {
		setPositionIds(selected.flatMap( group => group.positions));
		setSelectedDepartmentsAndPositions(selected);
		setSelectedGroups('custom');
	}

	useLayoutEffect(() => {
		if(startDate && endDate) {
			const monthDifference = differenceInMonths(new Date(endDate), new Date(startDate));

			if(monthDifference >= 1) {
				setCalendarZoomLevel(2);
			} else {
				setCalendarZoomLevel(1);
			}
		}
	}, [startDate, endDate])

	useEffect(() => {
		if(departmentsAndPositionsData?.data) {
			const _selectedDepartmentsAndPositions = departmentsAndPositionsData?.data?.map( department => ({
				department: department.id,
				positions: department.positions.map( position => position.id)
			}))

			setPositionIds(_selectedDepartmentsAndPositions.flatMap( group => group.positions));
			setSelectedDepartmentsAndPositions(_selectedDepartmentsAndPositions);
		}
	}, [departmentsAndPositionsData]);

	const [showDayKindModal, setShowDayKindModal] = useState(false);
	const [defaultDayKind, setDefaultDayKind] = useState<string | undefined>();
	const [dayKindStartDate, setDayKindStartDate] = useState<Date | undefined>();
	const [crewMemberData, setCrewMemberData] = useState<{id: number; first_name: string; last_name: string; rotation: string; position_id: number; partner_id: number} | null | undefined>();

	const openDayKindModalHandler = () => {
		setShowDayKindModal(true);
	}

	const closeDayKindModalHandler = () => {
		setShowDayKindModal(false);
		setDefaultDayKind(undefined);
		setDayKindStartDate(undefined);
	}

	const [moveChangeDay, setMoveChangeDay] = useState<{from: null | string, to: null | string}>({
		from: null,
		to: null
	});

	const onCloseMoveChangeDayModal = () => {
		setMoveChangeDay({
			from: null,
			to: null
		});
	}

	const onClickDayHandler = ({	
		day,
		crewMemberData
	}: {
		day: {
			date: string;
			type: string;
		},
		crewMemberData: {
			id: number;
			first_name: string;
			last_name: string;
			rotation: string;
			position_id: number;
			partner_id: number;
		}
	}) => {
		if(day.type === 'undefined') return;

		setCrewMemberData(crewMemberData);

		if((day.type === DAY_TYPE.Change || day.type === DAY_TYPE.TravelAndChange)) {
			if(moveChangeDay.from === null) {
				setMoveChangeDay(prevState => ({
					...prevState,
					from: day.date
				}));
				return;
			}
		}
		if(moveChangeDay.from !== null && moveChangeDay.to === null) {
			if(moveChangeDay.from === day.date) {
				setMoveChangeDay({
					from: null,
					to: null
				});
			} else {
				setMoveChangeDay(prevState => ({
					...prevState,
					to: day.date
				}));
				return;
			}
		}

		setDefaultDayKind(day.type);
		setDayKindStartDate(new Date(day.date));
		openDayKindModalHandler();
	}


	const [selectedDocument, setSelectedDocument] = useState<any>(null);
	const [userId, setUserId] = useState<string | undefined>();
	const [showDocumentModal, setShowDocumentModal] = useState(false);
	const onCloseDocumentModalHandler = () => {
		// setSelectedDocument(null);
		// setUserId(undefined);
		setShowDocumentModal(false);
	}

	const onClickDocumentHandler = ({
		documentBatchData,
		userId
	}: {
		documentBatchData: any;
		userId: string;
	}) => {
		setUserId(userId);
		setSelectedDocument(documentBatchData);
		setShowDocumentModal(true);
	}

	const generateMonthCells = ({crewMember, calendarData} : {
		crewMember: {
			id: number;
			first_name: string;
			last_name: string;
			rotation: string;
			position_id: number;
			partner_id: number;
		};
		calendarData: {
			duration: number;
			start_date: string;
			type: string;
		}[]
	}) => {
		if(!calendarData) return null;

		return calendarData.map((day: {
			duration: number;
			start_date: string;
			type: string;
		}) => {
			return (
				[...Array(day.duration)].map((_, index) => {
					const date = addDays( formatDateToStandarizedForm(day.start_date), index);
					const formattedDate = formatFns(date, "y-MM-dd");
					const extrudeData = calendarGeneralAdditionalData?.data?.positions?.[crewMember.position_id]?.[crewMember.id]?.[formattedDate];

					return (
						<CalendarCell 
							key={index}
							label={((day.type === DAY_TYPE.Change) || (day.type === DAY_TYPE.TravelAndChange)) && (
								<img src={RotatLogo} alt="Rotat Logo" className={cn("inline-block", calendarSizesClasses[calendarZoomLevel].changeLogo)}/>
							)}
							onClick={() => onClickDayHandler({
								day: {
									date: formattedDate, 
									type: day.type
								},
								crewMemberData: crewMember
							})}

							type={ (formattedDate === moveChangeDay.from) && (crewMemberData.id === crewMember?.id) ?  "highlight" : day.type}

							onClickDocument={onClickDocumentHandler}

							date={date}
							showTooltip={true}

							notesIds={ extrudeData?.notes || [] }
							documentsIds={ extrudeData?.documents || [] }

							crewMemberData={[{
								id: crewMember.id,
								position_id: crewMember.position_id
							}]}
						/>
					)
				})
			);
		});
	}

	const selectCrewMemberHandler = (crewMember: any) => {
		setSelectedCrewMemberId(crewMember);
	}

	const setSelectedGroupsHandler = (groups: string | undefined | Group[]) => {
		if(groups === selectedGroups) return;

		setSelectedGroups(prevState => {
			if(prevState === groups) return 'custom';
			return groups;
		});

		if(['all'].includes(groups)) {
			if(!departmentsAndPositionsData) return;

			const _selectedDepartmentsAndPositions = departmentsAndPositionsData?.data?.map( department => ({
				department: department.id,
				positions: department.positions.map( position => position.id)
			}))

			setPositionIds(_selectedDepartmentsAndPositions.flatMap( group => group.positions));
			setSelectedDepartmentsAndPositions(_selectedDepartmentsAndPositions);
		}
	}

	const getShortNameOfDay = ({year, month, day}: {year: number, month: number, day: number}) => {
		const date = new Date(year, month - 1, day);
		return formatFns(date, "EEE");
	};

	const isCurrentDate = (date: string) => {
		return isToday(date);
	}

	const getDaysInMonth = (month: number | string, year: number | string) => {
		const monthNum = Number(month);
		const yearNum = Number(year);
		const daysInMonths = [31, (yearNum % 4 === 0 && (yearNum % 100 !== 0 || yearNum % 400 === 0)) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		return daysInMonths[monthNum - 1];
	}

	const getMonthName = (month: number, months: {
		label: string;
		value: number;
	}[]) => {
		return months.find( _month => _month.value === month)?.label;
	}

	const calendarMonthsData = useMemo(() => {
		if(!startDate || !endDate) return [];

		const d_startDate = new Date(startDate);
		const d_endDate = new Date(endDate);

		const result = [];
		let currentDate = new Date(d_startDate);

		while(currentDate <= d_endDate) {
			const currentMonth = getMonth(currentDate) + 1;
			const currentYear = getYear(currentDate);

			result.push({
				days: getDaysInMonth(currentMonth, currentYear),
				month: currentMonth,
				year: currentYear,
				name: getMonthName(currentMonth, months)
			});
			currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
		}

		return result;
	}, [startDate, endDate]);

	const calendarDaysData = useMemo(() => {
		if(!calendarData?.data || !startDate || !endDate) return null;

		const dayDifference = differenceInDays(new Date(endDate), new Date(startDate)) + 1;

		const preparedData = calendarData.data.departments.map((department: DepartmentInterface[]) => {
			const positions = department.positions.map((position: PositionInterface) => {
				const crew = position.crew.map((crewMember: CrewInterface) => {

					const calendarWithEmptyDays = {};
					for(let i = 0; i < dayDifference; i++) {
						const currentDate = addDays(startDate, i);
						const month = getMonth(currentDate) + 1;
						const year = getYear(currentDate);
						const day = getDate(currentDate);
						const monthKey = `${year}-${month}-${day}`;
						calendarWithEmptyDays[monthKey] = 'undefined';
					}

					const currentCalendar = crewMember.calendar.reduce((acc, calendar) => {
						const _calendar = {};
						for(let i = 0; i < calendar.duration; i++) {
							const currentDate = addDays(calendar.start_date, i);
							const month = getMonth(currentDate) + 1;
							const year = getYear(currentDate);
							const day = getDate(currentDate);
							const monthKey = `${year}-${month}-${day}`;
							_calendar[monthKey] = calendar.type;
						}

						return {...acc, ..._calendar};
					}, {});

					const mergedCalendar = {...calendarWithEmptyDays, ...currentCalendar};

					const sortedCalendar = Object.entries(mergedCalendar).reduce((acc, [key, value]) => {
						const [year, month] = key.split("-");
						const monthKey = `${year}-${month}`;
						if(!acc[monthKey]) {
							acc[monthKey] = [];
						}
						acc[monthKey].push({
							type: value,
							duration: 1,
							start_date: key
						});
						return acc;
					}, {});

					const preparedCalendar = Object.entries(sortedCalendar).reduce((acc, [monthKey, days]) => {
						acc[monthKey] = days.reduce((acc, day) => {
							const lastDay = acc[acc.length - 1];
							if(lastDay && lastDay.type === day.type) {
								lastDay.duration += 1;
							} else {
								acc.push({...day});
							}
							return acc;
						}, []);

						return acc;
					}, {});

					return {...crewMember, calendar: preparedCalendar};
				});

				return {...position, crew}
			});

			return {...department, positions};
		})


		return preparedData;
	}, [calendarData, startDate, endDate]);


	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const scrollLeft = e.target.scrollLeft;
		const scrollWidth = e.target.scrollWidth; 
		const clientWidth = e.target.clientWidth; 

		currentScrollPosition.current = {
			scrollLeft,
			scrollWidth,
			clientWidth
		}

		const element = calendarContainerCrewLabelsRef.current;
		if (element) {
			element.scrollTop = e.currentTarget.scrollTop
		}

		const elementDays = calendarDaysContainerRef.current;
		if (elementDays) {
			elementDays.scrollLeft = scrollLeft;
		}

	};

	useEffect(() => {
		// On zoom level change scroll to the same position
		const element = calendarContainerRef.current;
		if (element) {
			const scrollWidth = element.scrollWidth; 
			const clientWidth = element.clientWidth; 

			const { scrollLeft: prevScrollLeft, scrollWidth: prevScrollWidth, clientWidth: prevClientWidth } = currentScrollPosition.current;
			const scrolled = (prevScrollLeft / (prevScrollWidth - prevClientWidth));

			const newScrollLeft = (scrollWidth - clientWidth) * (scrolled);
			element.scrollLeft = newScrollLeft;
		  }
	}, [calendarZoomLevel]);

	useEffect(() => {
		// Scroll to the beginning of the calendar
		const timeoutId = setTimeout(() => {
			const element = calendarContainerRef.current;
			element.scrollLeft = 0;
		}, 0);
		return () => clearTimeout(timeoutId);
	}, [startDate, endDate])

	const getNotesIds = ({
		calendarGeneralAdditionalData,
		positionId,
		crewMemberId,
		date
	}: {
		calendarGeneralAdditionalData: any;
		date: Date | undefined;
		positionId: number | undefined;
		crewMemberId: number | undefined;
	}) => {
		if(!calendarGeneralAdditionalData || !date || !positionId || !crewMemberId) return [];
		const notesIds = calendarGeneralAdditionalData?.data?.positions?.[positionId]?.[crewMemberId]?.[formatFns(date, "y-MM-dd")]?.notes || [];
		return notesIds;
	}

	return (
		<>
			<DocumentModal 
				show={showDocumentModal}
				onHide={onCloseDocumentModalHandler}
				document={selectedDocument}
				userId={userId}
			/>

			<AdaptModal
				show={moveChangeDay.from !== null && moveChangeDay.to !== null}
				onHide={onCloseMoveChangeDayModal}
				defaultStartDate={moveChangeDay.from}
				defaultEndDate={moveChangeDay.to}

				crewMemberId={crewMemberData?.id}
    			crewMemberName={crewMemberData?.first_name ? `${crewMemberData?.first_name} ${crewMemberData?.last_name}` : ""}

				rotation={crewMemberData?.rotation}
				positionId={crewMemberData?.position_id}
			/>

			<SelectYear 
				show={showYearDialog}
				onHide={hideYearDialogHandler}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>

			<Filters 
				show={showFilters}
				onHide={hideFiltersDialogHandler}

				defaultOnOffView={onOffView}
				onChangeOnOffView={setOnOffView}

				selectedDepartmentsAndPositions={selectedDepartmentsAndPositions}
				setSelectedDepartmentsAndPositions={selectedDepartmentsAndPositionsHandler}
				data={departmentsAndPositionsData?.data}
			/>

			<RotationCalendarDayKindDialog 
				closeDayKindModalHandler={closeDayKindModalHandler}
				showDayKindModal={showDayKindModal}
				vessel_id={vessel_id}
				user_id={crewMemberData?.id}
				userName={`${crewMemberData?.first_name} ${crewMemberData?.last_name}`}
				setDayKindStartDate={setDayKindStartDate}
				dayKindStartDate={dayKindStartDate}
				defaultDayKind={defaultDayKind}

				notesIds={getNotesIds({
					calendarGeneralAdditionalData: calendarGeneralAdditionalData,
					positionId: crewMemberData?.position_id,
					crewMemberId: crewMemberData?.id,
					date: dayKindStartDate
				})}
			/>

			<WhiteBox className="relative flex flex-col min-h-96 grow overflow-y-auto">

				<div className="relative flex flex-col grow">
					<ControlPanel 
					    isLoadingDepartments={isLoadingDepartments}
						selectedGroups={selectedGroups}
						setSelectedGroupsHandler={setSelectedGroupsHandler}
						calendarZoomLevel={calendarZoomLevel}
						setCalendarZoomLevel={setCalendarZoomLevel}
						showFiltersDialogHandler={showFiltersDialogHandler}
					/>

					<hr className="my-2" />

					<div className="flex">
						{/* Labels */}
						<div className="pe-3 w-52">
							<CalendarRowLabel label="Selected year and month(s):" />
						</div>

						{/* Data */}
						<div id="ship-calendar-datepicker" className="grow flex flex-col">
							<div className="mb-2">
								<CalendarCell label={
									isLoadingAvailableDates
									? <ButtonLoader isLoading={true} />
									: <p><span className="font-medium">{format(startDate, "MMM d, yyyy")}</span> {startDate && endDate ? 'to' : ''} <span className="font-medium">{format(endDate, "MMM d, yyyy")}</span></p>
								} className="" onClick={showYearDialogHandler}/>
							</div>
						</div>
					</div>

					<div className="flex relative overflow-hidden">
						{/* Labels */}
						{ (startDate && endDate) && (
							<div className="pe-3 w-52 shrink-0 overflow-hidden" >

								<div className="sticky top-0 flex flex-col relative z-10 bg-white">
									<div className="mb-2 h-12 border border-transparent"></div>
									<CalendarRowLabel label="Day" />
								</div>
							</div>
						)}
						
						{/* Data */}
						<div className="flex overflow-hidden relative grow pe-[10px]" ref={calendarDaysContainerRef}>
							{ calendarMonthsData?.map((calendarMonthData) => (
								
								<div className="grow px-2 mb-1 border-e first:ps-0 last:border-none last:pe-0" key={calendarMonthData.name}>
									<div className="sticky top-0 flex flex-col relative z-10 bg-white">
										<div className="mb-2">
											<CalendarCell 
												label={calendarMonthData.name}
											/>
										</div>
									
										<div className={cn("mb-2 flex shrink-1", calendarSizesClasses[calendarZoomLevel].gap)}>
											{[...Array(calendarMonthData.days)].map((_, index) => (
												<CalendarCell 
													key={index}
													label={(
														<div className="flex flex-col justify-center items-center">
															<div>{ index + 1 }</div>
															{ (calendarZoomLevel === 1) && (
																<div className="text-xs">{ getShortNameOfDay({
																	year: calendarMonthData.year,
																	month: calendarMonthData.month,
																	day: index + 1
																}) }</div>
															)}
														</div>
													)}
													className={cn(
														isCurrentDate(`${calendarMonthData.year}-${calendarMonthData.month}-${index+1}`) && 'calendar-current-day', 
														calendarSizesClasses[calendarZoomLevel].days,
														selectedDate === `${calendarMonthData.year}-${calendarMonthData.month}-${index+1}` && 'bg-orange-400 text-white'
													)}
													onClick={() => selectMonthDayHandler(`${calendarMonthData.year}-${calendarMonthData.month}-${index+1}`)}
												/>
											))}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>

					
					<div className="flex relative overflow-hidden grow" style={{minHeight: 330, height: 'calc(100vh - 56px - 32px - 32px - 48px - 17px - 56px - 116px)'}}>
						{/* Labels */}
						{ (startDate && endDate) && (
							<div className="pe-3 w-52 shrink-0 overflow-hidden" ref={calendarContainerCrewLabelsRef}>
								<LabelsSkeletonLoader
									isLoading={isLoadingCalendarData || isFetchingCalendarData || isLoadingCalendarGeneralAdditionalData || isFetchingCalendarGeneralAdditionalData}
								/>

								{ (!isLoadingCalendarData && !isFetchingCalendarData && !isLoadingCalendarGeneralAdditionalData && !isFetchingCalendarGeneralAdditionalData) && (calendarData?.data?.departments?.map( department => (
									<div className="grow" key={department.id} >

										<div className="h-3.5 text-sm text-slate-400 leading-none">{department.name || "-"}</div>
										<hr className="pb-1"/>
										
										{ department.positions.map( position => (
											<div className="grow" key={position.id}>
												<div className="mb-2">
													{ position.crew.map( crewMember => (
														<CalendarRowLabel 
															key={crewMember.id} 
															label={(
																<div className="flex flex-col">
																	<div className="leading-none mb-[2px]">{crewMember.first_name || "-"} {crewMember.last_name || "-"}</div>
																	<div className="text-xs font-normal leading-none">{position.name || "-"}</div>
																</div>
															)} 
															isClickable={true}
															onClick={() => selectCrewMemberHandler(crewMember.id)}
															className={selectedCrewMemberId === crewMember.id ? "bg-orange-300" : "bg-slate-100"}
														/>
													))}

													{ !position.crew?.length && (
														<div className="text-sm italic font-light">{position.name}</div>
													)}
												</div>
											</div>
										))}

										
									</div>
								)))}
							</div>
						)}

						{/* Data */}
						<div className="flex overflow-scroll relative grow" ref={calendarContainerRef} onScroll={handleScroll}>
							{ calendarMonthsData?.map((calendarMonthData) => (
								
								<div className="grow px-2 mb-1 border-e first:ps-0 last:border-none last:pe-0" key={calendarMonthData.name}>
									
									<div className="flex flex-col top-0 left-0 z-10 bg-white overflow-hidden h-0">
										<div className={cn("mb-2 flex shrink-0", calendarSizesClasses[calendarZoomLevel].gap)}>
											{/* Month Day */}
											{[...Array(calendarMonthData.days)].map((_, index) => (
												<CalendarCell 
													key={index}
													label={(
														<div className="flex flex-col justify-center items-center">
															<div>{ index + 1 }</div>
															{ (calendarZoomLevel === 1) && (
																<div className="text-xs">{ getShortNameOfDay({
																	year: calendarMonthData.year,
																	month: calendarMonthData.month,
																	day: index + 1
																}) }</div>
															)}
														</div>
													)}
													className={cn(calendarSizesClasses[calendarZoomLevel].days, 'text-transparent bg-transparent')}
												/>
											))}
										</div>
									</div>

									<CalendarCellsSkeletonLoader 
									    isLoading={isLoadingCalendarData || isFetchingCalendarData || isLoadingCalendarGeneralAdditionalData || isFetchingCalendarGeneralAdditionalData}
										calendarZoomLevel={calendarZoomLevel}
										calendarMonthData={calendarMonthData}
									/>
									{ (!isLoadingCalendarData && !isFetchingCalendarData && !isLoadingCalendarGeneralAdditionalData && !isFetchingCalendarGeneralAdditionalData) && (calendarDaysData?.map( department => (
										<div className="grow" key={department.id}>

											<div className="h-3.5"></div>
											<hr className="pb-1"/>

											{ department.positions.map( position => (
												<div className="grow" key={position.id}>
														{ position.crew.map( crewMember => 
															<div key={crewMember.id} className={cn("mb-2 flex", calendarSizesClasses[calendarZoomLevel].gap)}>
																{generateMonthCells({crewMember: crewMember ,calendarData: crewMember.calendar[`${calendarMonthData.year}-${calendarMonthData.month}`]})}
															</div>
														)}
														{ !position.crew?.length && (
															<div className="text-sm italic font-light mb-2">No data for &apos;{position.name}&apos;</div>
														)}
												</div>
											))}

										</div>
									)))}
								</div>
							))}
						</div>

					</div>
				</div>

			</WhiteBox>
		</>
	);
};
