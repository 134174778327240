interface FileNameCreatorProps {
    prefix: string | undefined;
    fileName: string;
    fileDates?: string[];
    fileExtension?: string;
}  
export const createFileName = ({
    prefix='',
    fileName,
    fileDates,
    fileExtension
}: FileNameCreatorProps) => {
    const formattedPrefix = prefix.replace(/ /g, '_');
    const formattedDates = fileDates?.join('_');

    return `${formattedPrefix}_${fileName}_${formattedDates}${fileExtension ? `.${fileExtension}` : ''}`;
};
