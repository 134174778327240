import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { useGetVesselQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { DepartmentAndPositionsDependentSelects } from "@/components/forms/DepartmentAndPositionsDependentSelects";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Checkbox } from "@/components/ui/checkbox";
import { DialogClose } from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { cn } from "@/lib/utils";
import { type FormProps } from "@/types/Form";
import { format as formatFns } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { getFormSchema, type FormType } from "./formSchema";

type Props = FormProps<FormType> & {
	hardcodedRole?: "manager";
};

export const UserUpsertForm = ({
	hardcodedRole,
	onSubmit,
	isLoading,
	defaultValues = {
		firstName: "",
		lastName: "",
		role: "",
		email: "",
		phone: "",
		nationality: "",
		homeAirport: "",
		department: "",
		position: "",
		hod: false,
		salary: undefined,
		currency: undefined,
		startDay: undefined,
		promotionDay: undefined,
		annualLeaveAllowance: undefined,
	},
}: Props) => {
	const auth = useAuth();
	const vesselId = useCurrentVesselId();
	const role = auth?.user?.role?.name || "";
	const isAdmin = ["admin"].includes(role);
	const schema = getFormSchema(isAdmin);

	const form = useForm<FormType>({
		resolver: zodResolver(schema),
		defaultValues: hardcodedRole ? { ...defaultValues, role: hardcodedRole } : defaultValues,
	});
	const { handleSubmit, control, setValue } = form;

	const { data: vesselResponse, isLoading: isLoadingVessel } = useGetVesselQuery(
		{ vesselId },
		{ skip: !vesselId },
	);

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="firstName"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">First Name</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="lastName"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Last Name</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className={`flex flex-col space-y-1.5 ${hardcodedRole ? "hidden" : ""}`}>
						<FormField
							control={control}
							name="role"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Role</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											{isLoadingVessel ? (
												<Skeleton className="h-10 w-full rounded-md" />
											) : (
												<Select
													onValueChange={field.onChange}
													defaultValue={hardcodedRole ? hardcodedRole : field.value}
												>
													<SelectTrigger>
														<SelectValue placeholder="Select Role" />
													</SelectTrigger>
													<SelectContent>
														<SelectGroup>
															<SelectItem value="user">User</SelectItem>
															<SelectItem value="manager">Manager</SelectItem>
															{["fleet_manager", "admin"].includes(role) && (
																<SelectItem
																	value="fleet_manager"
																	disabled={!vesselResponse?.data?.fleet}
																>
																	Fleet Manager
																</SelectItem>
															)}
														</SelectGroup>
													</SelectContent>
												</Select>
											)}
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="email"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Email</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input type="email" placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="phone"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Phone</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input type="tel" placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="nationality"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Nationality</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="homeAirport"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Home airport</FormLabel>
											<FormMessage />
										</div>

										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<DepartmentAndPositionsDependentSelects
						control={control}
						setValue={setValue}
						vesselId={Number(vesselId)}
					/>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="hod"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={field.onChange} />
									</FormControl>
									<FormLabel className="font-normal">is Head of Department?</FormLabel>
								</FormItem>
							)}
						/>
					</div>
					{["manager", "fleet_manager"].includes(role) && (
						<div className="flex flex-col space-y-1.5">
							<FormField
								control={control}
								name="salary"
								render={({ field }) => (
									<FormItem>
										<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
											<div className="flex items-center justify-start gap-2">
												<FormLabel className="whitespace-nowrap">Salary</FormLabel>
												<FormMessage />
											</div>
											<div className="relative flex flex-nowrap gap-1">
												<FormControl>
													<Input placeholder="" type="number" step="0.01" {...field} />
												</FormControl>
												<FormField
													control={control}
													name="currency"
													render={({ field }) => (
														<FormItem>
															<FormControl>
																<>
																	<Select onValueChange={field.onChange} defaultValue={field.value}>
																		<SelectTrigger className="w-[100px]">
																			<SelectValue placeholder="Currency" />
																		</SelectTrigger>
																		<SelectContent>
																			<SelectGroup>
																				<SelectItem value="eur">EUR</SelectItem>
																				<SelectItem value="usd">USD</SelectItem>
																				<SelectItem value="gbp">GBP</SelectItem>
																				<SelectItem value="pln">PLN</SelectItem>
																				<SelectItem value="aud">AUD</SelectItem>
																				<SelectItem value="chf">CHF</SelectItem>
																			</SelectGroup>
																		</SelectContent>
																	</Select>
																	<FormMessage className="absolute -right-2.5 -top-4" />
																</>
															</FormControl>
														</FormItem>
													)}
												/>
											</div>
										</div>
									</FormItem>
								)}
							/>
						</div>
					)}
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="startDay"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Start day</FormLabel>
											<FormMessage />
										</div>
										<Popover modal={true}>
											<PopoverTrigger asChild>
												<FormControl>
													<Button
														variant={"outline"}
														className={cn(
															"pl-3 text-left font-normal",
															!field.value && "text-muted-foreground",
														)}
													>
														{field.value ? formatFns(field.value, "PPP") : <span>Pick a date</span>}
														<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
													</Button>
												</FormControl>
											</PopoverTrigger>
											<PopoverContent className="w-auto p-0" align="start">
												<Calendar
													mode="single"
													selected={field.value}
													onSelect={field.onChange}
													disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
													initialFocus
												/>
											</PopoverContent>
										</Popover>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="promotionDay"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Promotion day</FormLabel>
											<FormMessage />
										</div>
										<Popover modal={true}>
											<PopoverTrigger asChild>
												<FormControl>
													<Button
														variant={"outline"}
														className={cn(
															"pl-3 text-left font-normal",
															!field.value && "text-muted-foreground",
														)}
													>
														{field.value ? formatFns(field.value, "PPP") : <span>Pick a date</span>}
														<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
													</Button>
												</FormControl>
											</PopoverTrigger>
											<PopoverContent className="w-auto p-0" align="start">
												<Calendar
													mode="single"
													selected={field.value}
													onSelect={field.onChange}
													disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
													initialFocus
												/>
											</PopoverContent>
										</Popover>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="annualLeaveAllowance"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Annual leave allowance</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" type="number" step="0.01" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex justify-between">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};
