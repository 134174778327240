import { useEffect, useState } from "react";

import { Calendar } from "@/components/ship-calendar/Calendar";
import { Controls } from "@/components/ship-calendar/Controls";
import { StatisticsByDepartment } from "@/components/ship-calendar/StatisticsByDepartment";
import { useJoyride } from "@/contexts/JoyrideContext";
import { Payrolls } from "@/components/ship-calendar/Payrolls";

export const ShipCalendar = () => {
	const [selectedCrewMemberId, setSelectedCrewMemberId] = useState<number | string | undefined>();

	const [selectedDate, setSelectedDate] = useState< string | null | undefined>();
	const [selectedPositionIds, setSelectedPositionIds] = useState< string[] | number[] | undefined | null>();

	const [isMinimized, setIsMinimized] = useState(true);
	const maximizeControlsHandler = () => {
        setIsMinimized(false);
    }

    const minimalizeControlsHandler = () => {
        setIsMinimized(true);
    }

	const selectedDateHandler = (date: string | null | undefined) => {
		setSelectedDate(date);
		if(date) {
			setIsMinimized(false);
		}
	};

	const selectedCrewMemberIdHandler = (date: number | string | undefined) => {
		setSelectedCrewMemberId(date);
		if(date) {
			setIsMinimized(false);
		}
	};

	// JOYRIDE STARTER
	const { startJoyride } = useJoyride();
	useEffect(() => {
		setTimeout(() => {
			startJoyride();
		}, 400);
	}, []);
	// END JOYRIDE STARTER

	return (
		<div id="ship-calendar-page" className="flex flex-col lg:flex-row">
			<Calendar
				selectedCrewMemberId={selectedCrewMemberId}
				setSelectedCrewMemberId={selectedCrewMemberIdHandler}
				selectedDate={selectedDate}

				onSetSelectedDate={selectedDateHandler}
				onSetSelectedPositionIds={setSelectedPositionIds}
			/>

			<section className="hidden lg:block flex">
				<Controls 
					selectedCrewMemberId={selectedCrewMemberId}

					minimizable={true}
					isMinimized={isMinimized}
					onMaximize={maximizeControlsHandler}
					onMinimalize={minimalizeControlsHandler}
				/>

				<Payrolls
					minimizable={true}
					isMinimized={isMinimized}
					onMaximize={maximizeControlsHandler}
					onMinimalize={minimalizeControlsHandler}
				/>

				<StatisticsByDepartment 
					date={selectedDate}
					positionIds={selectedPositionIds}
				
					minimizable={true}
					isMinimized={isMinimized}
					onMaximize={maximizeControlsHandler}
					onMinimalize={minimalizeControlsHandler}
				/>
			</section>
		</div>
	);
};
