import { getWeek, format as formatFns } from "date-fns";
import { type CalculateRotationWeekNumberArgs } from "@/types/Calendar";

export const DAYS_IN_WEEK = 7;

export const DAY_TYPE = {
	Onboard: "onboard_day",
    Travel: "travel_day",
    Change: "change_day",
    TravelAndChange: "travel_day_change_day",
    RotationalLeave: "rotational_leave",
    Training: "training_day",
    PaidOut: "paid_out_day",
    CompassionateLeave: "compassionate_leave",
    End: "end_day",
    MedicalLeave: "medical_leave",
    Other: "other_day",
	Empty: "empty_day"
} as const;

export function getDayType(type: string) {
    switch (type) {
        case DAY_TYPE.Onboard:
            return "Onboard Day";
        case DAY_TYPE.Travel:
            return "Travel Day";
        case DAY_TYPE.Change:
            return "Change Day";
        case DAY_TYPE.RotationalLeave:
            return "Rotational Leave";
        case DAY_TYPE.Training:
            return "Training Day";
        case DAY_TYPE.PaidOut:
            return "Paid Out Day";
        case DAY_TYPE.CompassionateLeave:
            return "Compassionate Leave";
        case DAY_TYPE.End:
            return "End Day";
        case DAY_TYPE.MedicalLeave:
            return "Medical Leave";
		case DAY_TYPE.TravelAndChange:
			return "Travel Day / Change Day";
        default:
            return "";
    };
}

export function getDayNameFromDate(date: string | Date) {
	const formattedDate = new Date(date);
	return new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(formattedDate);
}

export function getMonthNameFromDate(date: string | Date) {
	const formattedDate = new Date(date);
	return new Intl.DateTimeFormat("en-US", { month: "short" }).format(formattedDate);
}

export const legend = [
	{ type: DAY_TYPE.Onboard, name: "Onboard" },
	{ type: DAY_TYPE.Travel, name: "Travel" },
	{ type: DAY_TYPE.Change, name: "Change" },
	{ type: DAY_TYPE.RotationalLeave, name: "Rotational Leave" },
	{ type: DAY_TYPE.Other, name: "Other day"},
]

export function prepareDate(date: string, type: ('start_date' | 'end_date')) {
    if(!date) throw new Error("Date is not defined");
    const dateObj = new Date(date);
    const moveByDays = type === 'start_date' ? 0 : 0;
    dateObj.setDate(dateObj.getDate() + moveByDays);
    return formatFns(dateObj, "y-MM-dd");
}

export function getEmptyDaysCount(firstDateInData: number){
    if(firstDateInData === null || firstDateInData === undefined) throw new Error("First date in data is not defined");
    if (firstDateInData === 0) {
        return 6;
    }
    return firstDateInData - 1;
}

export function getEmptyFirstStartDate(nextDate: string, days: number){
    if(!nextDate || !days) return new Date();
    const date = new Date(nextDate);
    date.setDate(date.getDate() - days);
    return date;
}

export function calculateRotationWeekNumber({ rotation, page, data, rotationsDatesRanges, isPartner=false, rotationPeriodType }: CalculateRotationWeekNumberArgs): ({
    weekType: string;
    weekActive: boolean;
} | undefined)[] {
	if(!rotationPeriodType) return [];
    let restWeekNumber = 0;
	let onboardWeekNumber = 0;
	let changeDay = '';

	if( rotationPeriodType === 'months' ) {
		restWeekNumber = 1;
		onboardWeekNumber = 1;
	}

	function determineDayTypeForWeeks ({weekType, weekActive}: {weekType: string[], weekActive: boolean[]}, index: number) {
		// if page is first then return undefined for first week
		let text = '';

		if(index === 0 && ((page ? page : 1) === 1)) {
			text = 'undefined';
		} else if(index === 0 && ((page ? page : 1) !== 1)) {
			if(weekType[0] === 'onboard') {
				text = 'offboard';
			}
			text = 'onboard';
		} else {
			text = weekType[0];
		}
		
		return {
			weekType: text,
			weekActive: weekActive[0]
		}
	}

	function determineDayTypeForMonths ({weekType, weekActive}: {weekType: string[], weekActive: boolean[]}, index: number) {
		let text = '';
		
		if(index === 0 && ((page ? page : 1) === 1)) {
			text =  weekType[0];
		} else if(index === 0 && ((page ? page : 1) !== 1)) {
			if(weekType[0] === 'onboard') {
				text =  'offboard';
			}
			text = 'onboard';
		} else {
			text = weekType[0];
		}
		
		return {
			weekType: text,
			weekActive: weekActive[0]
		}
	}

	function prepareTextForWeeks ({weekType, weekActive}: {weekType: string, weekActive: boolean}, index: number, arr: {weekType: string, weekActive: boolean}[]) {
		// add week number to week type
		if(!rotation) return;
		const [onboardWeeks, offBoardWeeks] = rotation.split('/');
		const currentWeekType = arr[index].weekType;

		if(currentWeekType === "onboard") {
			onboardWeekNumber++;
			restWeekNumber = 0;
		} else if(currentWeekType === "offboard") {
			restWeekNumber++;
			onboardWeekNumber = 0;
		}

		let isFirst = false;
		let isSame = false;

		if(index === 0) {
			isFirst = true;
			if(arr[index + 1].weekType === currentWeekType) {
				isSame = true;
			}
		}

		const returnedValue = {
			weekType: "",
			weekActive
		}

		if(weekType === "onboard") {
			if(isFirst) {
				if(isSame) {
					returnedValue.weekType = `Onboard (${onboardWeekNumber}/${onboardWeeks})`;
				}
				returnedValue.weekType =  `Onboard (${onboardWeeks}/${onboardWeeks})`;
			}
			returnedValue.weekType =  `Onboard (${onboardWeekNumber}/${onboardWeeks})`;
		} else if(weekType === "offboard") {
			if(isFirst) {
				if(isSame) {
					returnedValue.weekType =  `Offboard (${restWeekNumber}/${offBoardWeeks})`;
				}
				returnedValue.weekType =  `Offboard (${offBoardWeeks}/${offBoardWeeks})`;
			}
			returnedValue.weekType =  `Offboard (${restWeekNumber}/${offBoardWeeks})`;
		} else {
			returnedValue.weekType =  '';
		}
		return returnedValue;
	}

	function prepareTextForMonths ({weekType, weekActive}: {weekType: string, weekActive: boolean}, index: number, arr: {weekType: string, weekActive: boolean}[]) {
		if(!rotation) return;
		const [onboardWeeks, offBoardWeeks] = rotation.split('/');

		const currentWeekType = arr[index].weekType;

		let isFirst = false;
		let isSame = false;

		if(index === 0) {
			isFirst = true;
			if(arr[index + 1].weekType === currentWeekType) {
				isSame = true;
			}
		}

		let text = '';

		if(weekType === "onboard") {
			if(isFirst) {
				if(isSame) {
					text = `Onboard (${onboardWeekNumber}/${onboardWeeks})`;
				}
				text = `Onboard (${onboardWeeks}/${onboardWeeks})`;
			}
			text = `Onboard (${onboardWeekNumber}/${onboardWeeks})`;
		} else if(weekType === "offboard") {
			if(isFirst) {
				if(isSame) {
					text = `Offboard (${restWeekNumber}/${offBoardWeeks})`;
				}
				text = `Offboard (${offBoardWeeks}/${offBoardWeeks})`;
			}
			text = `Offboard (${restWeekNumber}/${offBoardWeeks})`;
		} else {
			text = '';
		}

		if(currentWeekType === "onboard") {
			if(determineWhichWeekIsIncrement[index].includes('increment') && !isFirst) {
				onboardWeekNumber++;
			}
			restWeekNumber = 1;
		} else if(currentWeekType === "offboard") {
			if(determineWhichWeekIsIncrement[index].includes('increment') && !isFirst) {
				restWeekNumber++;
			}
			onboardWeekNumber = 1;
		}

		return {
			weekType: text,
			weekActive
		};
	}

	const periodTypesArray = data
	.map((d) => {
		// Generate array of arrays with periodType and date ie.: [["onboard_day", "2022-01-01"], ["change_day", "2022-01-02"], ...]
		const elements = [];

		const { type: periodType, duration: periodDuration } = d;

		for (let index = 0; index < periodDuration; index++) {
			const date = new Date(d.start_date);
			date.setDate(date.getDate() + index);

			elements.push([periodType, formatFns(date, "y-MM-dd")]);
		}
		return elements;
	})
	.flat()
	.reduce((acc: string[][][], curr, index) => {
		// Divide data into weeks
		const arrIndex = Math.floor(index / 7);

		if(index % 7 === 0) {
			acc.push([curr]);
		} else {
			acc[arrIndex].push(curr);
		}

		return acc;
	}, []);

	if(rotationPeriodType === 'leave_allowance') {
		const weeksNumber = periodTypesArray.map((week: string[][]) => {
			const firstDateOfWeek = week[0][1];
			const date = new Date(firstDateOfWeek);
	
			const weekNumber = getWeek(date);
			return  {
				weekType: String(weekNumber),
				weekActive: false
			};
		});
		return weeksNumber;
	}

	const determineWhichWeekIsIncrement = data.map((d) => {
		// Generate array of arrays with periodType and date ie.: [["onboard_day", "2022-01-01"], ["change_day", "2022-01-02"], ...]
		const elements = [];

		const { type: periodType, duration: periodDuration } = d;

		for (let index = 0; index < periodDuration; index++) {
			const date = new Date(d.start_date);
			date.setDate(date.getDate() + index);

			elements.push([periodType, formatFns(date, "y-MM-dd")]);
		}
		return elements;
	}).flat().reduce((acc: string[][][], curr, index) => {
		// Divide data into weeks
		const arrIndex = Math.floor(index / 7);

		if(index % 7 === 0) {
			acc.push([curr]);
		} else {
			acc[arrIndex].push(curr);
		}

		return acc;
	}, []).map((days: string[][]) => {
		const weekType: string[] = [];

		days.forEach((day) => {
			const [dayType, date] = day;
			
			if(dayType === 'change_day' || dayType === 'travel_day_change_day') {
				changeDay = date;
			}

			const dayDate = new Date(date).getDate();

			let changeDayDate = -1;
			if(changeDay) {
				const selectedDateMonth = new Date(date).getMonth();
				const selectedDateYear = new Date(date).getFullYear();
				const selectedDateLastDay = new Date(selectedDateYear, selectedDateMonth + 1, 0).getDate();
				const _changeDayDate = new Date(changeDay).getDate();

				// if changeDay is bigger then current month days count then set changeDayDate to last day of month
				if(_changeDayDate > selectedDateLastDay) {
					changeDayDate = selectedDateLastDay;
				} else {
					changeDayDate = _changeDayDate;
				}
			}

			if(dayDate === changeDayDate) {
				weekType.push('increment');
			} else {
				weekType.push('');
			}
		})

		const weekTypeSet = new Set(weekType);
		// weekType will be ie: 
		// 1.['', 'increment']
		// 2.['']

		return Array.from(weekTypeSet);
	});

	const today = formatFns(new Date(), "y-MM-dd");
	const determineDayType = periodTypesArray.map((days: string[][]) => {
		// Check if day is withing range in onboard or offboard
		const weekType: string[] = [];
		const weekActive: boolean[] = [];

		days.forEach((day) => {
			const [_dayType, date] = day;
			if(!rotationsDatesRanges) return 'undefined';

			rotationsDatesRanges.forEach((rotation) => {
				const { onboard, offboard } = rotation;

				if(!offboard || !onboard) return;

				const onboardEndDate = new Date(onboard.end_date);
				const offboardEndDate = new Date(offboard.end_date);

				// IMPORTANT! Adding + 1 to end date of onboard and offboard to include last day, 
				// remove it if onboard.end_date === offboard.start_date and offboard.end_date === onboard.start_date
				onboardEndDate.setHours(12);
				offboardEndDate.setHours(12);
				onboardEndDate.setDate(onboardEndDate.getDate() + 0);
				offboardEndDate.setDate(offboardEndDate.getDate() + 1);

				const isWithinOffboardRange = date >= offboard.start_date && date <= formatFns(offboardEndDate, "y-MM-dd");
				const isWithinOnboardRange = date >= onboard.start_date && date <= formatFns(onboardEndDate, "y-MM-dd");

				const isWithinOffboardRangeToday = today >= offboard.start_date && today <= formatFns(offboardEndDate, "y-MM-dd");
				const isWithinOnboardRangeToday = today >= onboard.start_date && today <= formatFns(onboardEndDate, "y-MM-dd");
				
				if(isPartner) {
					if(isWithinOffboardRange) {
						weekType.push('offboard');

						if(isWithinOffboardRangeToday) {
							weekActive.push(true);
						} else {
							weekActive.push(false);
						}
					}
					if(isWithinOnboardRange) {
						weekType.push('onboard'); 

						if(isWithinOnboardRangeToday) {
							weekActive.push(true);
						} else {
							weekActive.push(false);
						}
					}
				} else {
					if(isWithinOnboardRange) {
						weekType.push('onboard'); 

						if(isWithinOnboardRangeToday) {
							weekActive.push(true);
						} else {
							weekActive.push(false);
						}
					}
					if(isWithinOffboardRange) {
						weekType.push('offboard');

						if(isWithinOffboardRangeToday) {
							weekActive.push(true);
						} else {
							weekActive.push(false);
						}
					}
				}
			});
		})
		// weekType will be ie: 
		// 1.['onboard', 'onboard', 'onboard', 'onboard', 'onboard', 'onboard', 'onboard']
		// 2.['onboard', 'onboard', 'onboard', 'offboard', 'offboard', 'offboard', 'offboard', 'offboard']

		// weekType (Set) will be ie:
		// 1.['onboard']
		// 2.['onboard', 'offboard']
		return { weekType: Array.from(new Set(weekType)), weekActive: Array.from(new Set(weekActive)) };
	})

	const formattedRotationNumber = determineDayType
	.map( rotationPeriodType === 'months' ? determineDayTypeForMonths : determineDayTypeForWeeks )
	.map( rotationPeriodType === 'months' ? prepareTextForMonths : prepareTextForWeeks);

	return formattedRotationNumber;
}

export const formatDateToStandarizedForm = (date: string) => {
	if(typeof date !== 'string') return null;
	const formattedDate = date.replace(/-/g, '/');
	return formattedDate;
}

export const formatName = ({firstName, lastName}: {firstName: string, lastName: string}) => {
	if(firstName && lastName) {
		return `_${firstName}_${lastName}`;
	} else if(firstName) {
		return `_${firstName}`;
	} 
	return '';
}