import { useEffect, useState } from "react";

import { useAddCrewMemberMutation } from "@/app/api/slices/crewMembersApiSlice";
import { UserUpsertForm } from "@/components/forms/UserUpsertForm";
import { type FormType } from "@/components/forms/UserUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useJoyride } from "@/contexts/JoyrideContext";
import { crewMembersListingSteps } from "@/data/joyride/crew-members-listing";
import { joyride_redirect_3_index } from "@/hocs/JoyrideWrapper";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

const index_of_open_modal_step = crewMembersListingSteps.findIndex(
	(step) => step.target === "#add-crew-member-button",
);
const first_modal_step_index = joyride_redirect_3_index + index_of_open_modal_step + 1;

const index_of_last_modal_step = crewMembersListingSteps.findIndex(
	(step) => step.target === "#add-crew-member-rotation-field",
);
const close_index = joyride_redirect_3_index + index_of_last_modal_step + 1;

export const AddCrewMemberDialog = () => {
	const vesselId = useCurrentVesselId();

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [addCrewMemberMutation, { isLoading }] = useAddCrewMemberMutation();

	const { stepIndex, setStepIndex } = useJoyride();

	useEffect(() => {
		if (stepIndex === close_index) {
			setOpen(false);
		}
	}, [stepIndex]);

	const handleAddCrewMember = async (data: FormType) => {
		await addCrewMemberMutation({ vesselId, data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added crew member.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button
					id="add-crew-member-button"
					className="uppercase"
					onClick={() =>
						setTimeout(() => {
							setStepIndex(first_modal_step_index);
						}, 400)
					}
				>
					Add crew member
				</Button>
			</DialogTrigger>
			<DialogContent
				id="add-crew-member-dialog"
				className="sm:max-w-[500px]"
				onInteractOutside={(e) => {
					e.preventDefault();
				}}
			>
				<DialogHeader>
					<DialogTitle>Add Crew Member</DialogTitle>
				</DialogHeader>
				<UserUpsertForm onSubmit={handleAddCrewMember} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};
