import { skeletonLoaderData } from "./settings";
import { CalendarRowLabel } from "./CalendarRowLabel";

type LabelsSkeletonLoaderProps = {
    isLoading: boolean;
};

export const LabelsSkeletonLoader = ({
    isLoading,
}: LabelsSkeletonLoaderProps) => {


    return (
        <>
            { isLoading && (skeletonLoaderData.map( (department, index) => (
                <div className="grow" key={index}>

                    <div className="h-3.5 text-sm text-slate-400 leading-none">{""}</div>
                    <hr className="pb-1"/>
                    
                    { department.positions.map( (position, index) => (
                        <div className="grow" key={index}>
                            <div className="mb-2">
                                { position.crew.map( (_, index) => (
                                    <CalendarRowLabel 
                                        key={index} 
                                        label={(
                                            <div className="flex flex-col">
                                                <div className="leading-none mb-[2px]">{""} {""}</div>
                                                <div className="text-xs font-normal leading-none">{""}</div>
                                            </div>
                                        )} 
                                        className={'bg-slate-100 animate-pulse'}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )))}
        </>
    );
}
