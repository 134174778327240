import { apiSlice } from "@/app/api/apiSlice";

// tags: CalendarGeneralAdditionalData, CalendarAdditionalData
export const shipCalendarApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		checkCalendarAdditionalData: builder.query({
			query: ({
				vesselId,
				positionsIds,
				startDate,
				endDate
			}: {
				vesselId: number | string;
				positionsIds: number[] | string[];
				startDate: string;
				endDate: string;
			}) => `/api/vessels/${vesselId}/calendars/additional_data?positions=[${positionsIds}]&start_date=${startDate}&end_date=${endDate}`,
			providesTags: (_result, _error, { vesselId, positionsIds, startDate, endDate }) => [
				{ type: "CalendarGeneralAdditionalData", id: `${vesselId}${positionsIds}${startDate}${endDate}` },
			],
		}),
		getCalendarAdditionalData: builder.query({
			query: ({
				vesselId,
				documentsIds,
				notesIds
			}: {
				vesselId: number | string;
				documentsIds: number[] | string[];
				notesIds: number[] | string[];
			}) => {
				let URL = `/api/vessels/${vesselId}/calendars/additional_data/details`;
				if (documentsIds.length) {
					URL += `?d_id=[${documentsIds}]`
				}
				if (notesIds.length) {
					URL += documentsIds.length ? `&n_id=[${notesIds}]` : `?n_id=[${notesIds}]`;
				}
				return URL;
			},
			providesTags: (_result, _error, { vesselId, documentsIds, notesIds }) => [
				{ type: "CalendarAdditionalData", id: `${vesselId}${documentsIds}${notesIds}` },
			],
		}),
	}),
	overrideExisting: true,
});

export const {
	useCheckCalendarAdditionalDataQuery,
	useGetCalendarAdditionalDataQuery,
	useLazyGetCalendarAdditionalDataQuery,
} = shipCalendarApiSlice;
