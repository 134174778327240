import * as z from "zod";

import { emailZodRule, firstNameZodRule, lastNameZodRule, phoneZodRule } from "@/lib/zod";

export const baseSchema = z.object({
	firstName: firstNameZodRule,
	lastName: lastNameZodRule,
	email: z.string().optional(),
	role: z.string().min(1, { message: "Role is required" }),
	phone: phoneZodRule,
	nationality: z.string().min(1, { message: "Nationality is required" }),
	homeAirport: z.string().min(1, { message: "Home airport is required" }),
	department: z.string().min(1, { message: "Department is required" }),
	position: z.string().min(1, { message: "Position is required" }),
	// new optional fields
	hod: z.coerce.boolean().optional(), // Head of Department
	salary: z.preprocess(
		(value) => (value === "" ? undefined : value),
		z.coerce.number().positive().optional(),
	),
	currency: z.string().min(1, "Currency is required").optional().or(z.literal("")),
	startDay: z.date().optional(),
	promotionDay: z.date().optional(),
	annualLeaveAllowance: z.preprocess(
		(value) => (value === "" ? undefined : value),
		z.coerce.number().positive().optional(),
	),
});

export const nonAdminSchema = baseSchema
	.extend({
		email: emailZodRule, // Email is required
	})
	.refine(
		(data) => {
			// If salary is defined (and > 0), currency must not be empty
			return !data.salary || data.currency?.trim();
		},
		{
			message: "Currency is required if salary is filled",
			path: ["currency"],
		},
	);

export const adminSchema = baseSchema
	.extend({
		email: z.string().email({ message: "Invalid email address" }).optional().or(z.literal("")), // Email is optional
	})
	.refine(
		(data) => {
			// If salary is defined (and > 0), currency must not be empty
			return !data.salary || data.currency?.trim();
		},
		{
			message: "Currency is required if salary is filled",
			path: ["currency"],
		},
	);

export type FormType = z.infer<typeof baseSchema>;
export type AdminFormType = z.infer<typeof adminSchema>;

export const getFormSchema = (isAdmin: boolean) => (isAdmin ? adminSchema : nonAdminSchema);
