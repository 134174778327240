import { useState, useEffect } from "react";
import { useAuth } from "@/hooks/useAuth";
import { useGetFleetVesselsQuery } from "@/app/api/slices/fleet-manager/fleetApiSlice";

interface VesselInterface {
	name: string;
	id: string | number;
}

export function useCurrentVessel() {
	const [vessel, setVessel] = useState<VesselInterface>();

	const auth = useAuth();

	const role = auth?.user?.role;
	const {
		data: vesselsResponse
	} = useGetFleetVesselsQuery({
		enabled: role?.name === "fleet_manager",
	});

	useEffect(() => {
		if (role?.name === "fleet_manager") {
			const ship = vesselsResponse?.data?.find((vessel: VesselInterface) => vessel.id === auth?.user?.activeVesselId);
			setVessel(ship);
		} else {
			setVessel(auth?.user?.vessel);
		}
	}, [auth, role, vesselsResponse]);

	return vessel;
}
