import { ArrowRightToLine, FileChartColumn } from "lucide-react";

import RotatLogo from "@/assets/logo-small.svg";
import { Button } from "@/components/ui/button";
import { WhiteBox } from "@/components/WhiteBox";

import changeModalImg from "@/assets/joyride/ship-calendar-change-modal.png";
import doubleClickModalImg from "@/assets/joyride/ship-calendar-double-click-modal.png";

export const shipCalendarSteps = [
	// 37 - ROTATION CREATOR
	{
		target: "#ship-calendar-page",
		content: (
			<div>
				<h1 className="text-xl font-semibold">Overview of the all crew leave plans.</h1>
				<p>On this page you can see all crew and their schedules for selected time period.</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	{
		target: "#ship-calendar-filters",
		content: (
			<div>
				<p>Section with filters.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	{
		target: "#ship-calendar-filters-open-button",
		content: (
			<div>
				<p>Click the spotlight area to open filters.</p>
			</div>
		),
		disableBeacon: true,
		disableOverlayClose: true,
		hideCloseButton: true,
		hideFooter: true,
		spotlightClicks: true,
		placement: "auto",
		styles: {
			tooltipContent: {
				padding: "10px",
			},
			spotlight: {
				boxShadow: "0px 0px 20px 10px rgba(251, 133, 0, 0.4)",
			},
		},
	},
	{
		target: "#ship-calendar-filters-modal",
		content: (
			<div>
				<p>You filter by departments and positions. See schedules you currently need.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	{
		target: "#ship-calendar-page",
		content: (
			<div>
				<p className="mb-4">You can select crew member</p>
				<div className="pointer-events-none mx-auto mb-2 flex h-12 w-48 items-center justify-center rounded-md border bg-slate-100 px-3 text-center text-sm  font-medium">
					<div className="flex flex-col">
						<div className="mb-[2px] leading-none">Example Member</div>
						<div className="text-xs font-normal leading-none">Manager</div>
					</div>
				</div>
				<p className="my-4">Click on the statistics section</p>
				<div className="pointer-events-none flex items-center justify-center bg-[#8ecae6] p-4">
					<WhiteBox className="relative ms-4 flex h-[261px] flex-col items-center justify-center p-1">
						<Button variant={"ghost"} size="icon" className="pointer-events-none h-full">
							<FileChartColumn />
						</Button>
					</WhiteBox>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	{
		target: "#ship-calendar-page",
		content: (
			<div>
				<p className="mb-4">
					You select any time frame and see statistics and download report for selected crew member.
					After you select different crew member, timeframe doesn’t reset, so you can compare data
					with an easy way.
				</p>
				<div className="pointer-events-none mx-auto w-[340px] bg-[#8ecae6] p-4">
					<div className="relative rounded-md bg-white p-4">
						<div>
							<div className="flex justify-end">
								<button className="inline-flex h-auto w-10 items-center justify-center whitespace-nowrap rounded-md p-1 text-sm font-semibold ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
									<ArrowRightToLine />
								</button>
							</div>
							<span className="text-nowrap font-bold">Statistics for selected crew member</span>
						</div>
						<hr className="my-1 mb-2" />
						<div className="mb-2 text-center text-sm">Select a crew member</div>
						<div>
							<textarea
								className="flex min-h-[80px] w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
								placeholder="Remarks"
							></textarea>
						</div>
						<button className="mt-3 inline-flex h-10 w-full items-center justify-center whitespace-nowrap rounded-md bg-primary px-4 py-2 text-sm font-semibold text-primary-foreground ring-offset-background transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
							Download Report
						</button>
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	{
		target: "#ship-calendar-datepicker",
		content: (
			<div>
				<p>Click the spotlight area to open datepicker.</p>
			</div>
		),
		disableBeacon: true,
		disableOverlayClose: true,
		hideCloseButton: true,
		hideFooter: true,
		spotlightClicks: true,
		placement: "auto",
		styles: {
			tooltipContent: {
				padding: "10px",
			},
			spotlight: {
				boxShadow: "0px 0px 20px 10px rgba(251, 133, 0, 0.4)",
			},
		},
	},
	{
		target: "#ship-calendar-datepicker-modal",
		content: (
			<div>
				<p>You can select year and months here to overview generated schedules.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	{
		target: "#ship-calendar-page",
		content: (
			<div>
				<p className="mb-4">Click on the day to change day type.</p>
				<div className="mx-auto flex w-[340px] justify-center gap-2">
					<div className="pointer-events-none  h-12 w-12 overflow-hidden rounded-md border bg-[#8ed873] text-center text-sm hover:border-slate-400 hover:duration-300"></div>
					<div className="pointer-events-none  h-12 w-12 overflow-hidden rounded-md border bg-[#ff7e79] text-center text-sm hover:border-slate-400 hover:duration-300"></div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	{
		target: "#ship-calendar-page",
		content: (
			<div>
				<p className="mb-4">Click on the handover day marked in yellow to assign to new one.</p>
				<div className="mx-auto flex w-[340px] justify-center gap-2">
					<div className="pointer-events-none  flex h-12 w-12 items-center justify-center overflow-hidden rounded-md border bg-[#f2ff92] text-center text-sm hover:border-slate-400 hover:duration-300">
						<img src={RotatLogo} alt="Rotat Logo" className="inline-block w-4" />
					</div>
				</div>
				<p className="my-4">Then you can assign a new handover day.</p>
				<div className="mx-auto flex w-96 justify-center bg-[#8ecae6] p-4">
					<img src={changeModalImg} alt="" />
				</div>
				<p className="mt-4">
					If crew member has rotational partner and you would like to change this day for him as
					well, remember to tick this box. Rest of the schedule will adapt.
				</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	{
		target: "#ship-calendar-page",
		content: (
			<div>
				<p className="mb-4">
					If you double click on the handover day you can change it to Travel/change day.
				</p>
				<div className="mx-auto flex w-96 justify-center bg-[#8ecae6] p-4">
					<img src={doubleClickModalImg} alt="" />
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
];
