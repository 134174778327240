export const skeletonLoaderData = [
    {
        "positions": [
            {
                "crew": [
                    {
                        "calendar": []
                    }
                ]
            },
            {
                "crew": [
                    {
                        "calendar": []
                    }
                ]
            }
        ]
    },
    {
        "positions": [
            {
                "crew": [
                    {
                        "calendar": []
                    }
                ]
            }
        ]
    },
];

export const calendarSizesClasses: { [key: number]: { changeLogo: string; gap: string; days: string; } } = {
	1: {
		changeLogo: "w-4",
		gap: "gap-0.5",
		days: "min-w-7 px-[6px]",
	},
	2: {
		changeLogo: "w-2.5",
		gap: "gap-px",
		days: "min-w-3 ps-px pe-0.5 text-[10px]",
	}
};

export const months = [
	{ label: 'January', value: 1 },
	{ label: 'February', value: 2 },
	{ label: 'March', value: 3 },
	{ label: 'April', value: 4 },
	{ label: 'May', value: 5 },
	{ label: 'June', value: 6 },
	{ label: 'July', value: 7 },
	{ label: 'August', value: 8 },
	{ label: 'September', value: 9 },
	{ label: 'October', value: 10 },
	{ label: 'November', value: 11 },
	{ label: 'December', value: 12 }
]; 
