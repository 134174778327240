export const menuSteps = [
	// 1 - MENU
	{
		target: "#rotat-menu",
		content: (
			<div>
				<h1>Navigation menu</h1>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// // 2 - MENU
	// {
	// 	target: "#menu-positions",
	// 	content: (
	// 		<div>
	// 			<h1>Here is position calendar</h1>
	// 		</div>
	// 	),
	// 	disableBeacon: true,
	// 	placement: "auto",
	// },
	// // 3 - MENU
	// {
	// 	target: "#menu-crew",
	// 	content: (
	// 		<div>
	// 			<h1>Here is crew members listing with action buttons</h1>
	// 		</div>
	// 	),
	// 	disableBeacon: true,
	// 	placement: "auto",
	// },
	// // 4 - MENU
	// {
	// 	target: "#menu-payments",
	// 	content: (
	// 		<div>
	// 			<h1>Here is payment center</h1>
	// 		</div>
	// 	),
	// 	disableBeacon: true,
	// 	placement: "auto",
	// },
	// // 5 - MENU
	// {
	// 	target: "#menu-settings",
	// 	content: (
	// 		<div>
	// 			<h1>Here are profile settings</h1>
	// 		</div>
	// 	),
	// 	disableBeacon: true,
	// 	placement: "auto",
	// },
	// // 6 - MENU
	// {
	// 	target: "#menu-knowledge-base",
	// 	content: (
	// 		<div>
	// 			<h1>Here is knowledge base</h1>
	// 		</div>
	// 	),
	// 	disableBeacon: true,
	// 	placement: "auto",
	// },
	// // 7 - MENU
	// {
	// 	target: "#menu-suggestions",
	// 	content: (
	// 		<div>
	// 			<h1>Here is suggestion center</h1>
	// 		</div>
	// 	),
	// 	disableBeacon: true,
	// 	placement: "auto",
	// },
];
