/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ReactNode } from "react";
import { format as formatFns } from "date-fns";
import { cn } from "@/lib/utils";
import * as Tooltip from '@radix-ui/react-tooltip';
import { ButtonLoader } from "@/components/ButtonLoader";
import { useGetAppConfigQuery } from "@/app/api/slices/appConfigApiSlice";
import { useGetCellColor } from "@/hooks/useGetCellColor";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { 
	useLazyGetCalendarAdditionalDataQuery 
} from "@/app/api/slices/commonCalendarApiSlice";


type CalendarCellProps = {
    label?: ReactNode, 
    onClick?: () => void, 
    className?: string,
    type?: string,
    onClickDocument?: ({
        documentBatchData,
        userId
    }: {
        documentBatchData: any;
        userId: string;
    }) => void;
    date?: Date; 
    showTooltip?: boolean;
    crewMemberData?: {
        id: number;
        position_id: number;
    }[] | null | undefined;
    notesIds?: number[] | undefined;
    documentsIds?: number[] | undefined;
};

export const CalendarCell = ({ 
    label, 
    onClick=()=>{}, 
    className="bg-slate-50",
    type="",
    onClickDocument=()=>{},
    date,
    showTooltip=false,
    crewMemberData,
    notesIds,
    documentsIds
}: CalendarCellProps) => {
    const vesselId = useCurrentVesselId();

    const hasDocuments = Array.isArray(documentsIds) && !!documentsIds.length;
    const hasNotes = Array.isArray(notesIds) && !!notesIds.length;

    const [trigger, {data: additionalData, isLoading: isLoadingAdditionalData, isFetching: isFetchingAdditionalData} ] = useLazyGetCalendarAdditionalDataQuery();

    const { format } = useDateFormatter();
    const { data: configData } = useGetAppConfigQuery({});
    const formattedDate = date ? formatFns(date, "y-MM-dd") : "-";
    const { getClassFromType } = useGetCellColor();

    const onOpenChange = (isOpen: boolean) => {
        if(isOpen && vesselId && Array.isArray(notesIds) && Array.isArray(documentsIds) && (notesIds.length || documentsIds.length)) {
            trigger({
                vesselId: vesselId,
                documentsIds: documentsIds || [],
                notesIds: notesIds || []
            }, true);
        }
    };

    if(!showTooltip) {
        return (
            <div onClick={onClick} 
                style={{flexBasis: '100%'}} 
                className={cn(`overflow-hidden cursor-pointer grow h-12 border hover:border-slate-400 hover:duration-300 rounded-md flex justify-center items-center text-center text-sm`, className)}>
                {label}
            </div>
        )
    }

    return (
        <Tooltip.Provider>
            <Tooltip.Root onOpenChange={onOpenChange}>
                <Tooltip.Trigger asChild>
                    <div onClick={onClick} style={{flexBasis: '100%', background: getClassFromType(type)}} className={cn(`relative min-w-3 overflow-hidden cursor-pointer grow h-12 border hover:border-slate-400 duration-300 rounded-md flex justify-center items-center text-center text-sm`, className)}>
                        {label}

                        { hasDocuments && (
                            <div className="top-0 left-0 right-0 absolute h-2 bg-red-500 rotate-[45deg] translate-x-2/4"></div>
                        )}		

                        { hasNotes && (
                            <div className="top-0 left-0 right-0 absolute h-2 bg-blue-500 rotate-[-45deg] translate-x-[-50%]"></div>
                        )}

                    </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content className="TooltipContent" sideOffset={5}>
                        { showTooltip ? (
                            <div className="flex flex-col">
                                <div className="text-xs lg:text-sm font-medium capitalize">{ configData?.data?.day_types?.[type] }</div>
                                <div className="text-xs lg:text-sm">{ format(formattedDate) }</div>

                                { hasNotes && (
                                    <div>
                                        <div className="mt-2">Note{ notesIds.length > 1 ? 's' : ''}: </div>
                                        
                                        { (isLoadingAdditionalData || isFetchingAdditionalData) && (
                                            <div className="mt-1 p-1 bg-yellow-100 rounded">
                                                <p className="text-xs lg:text-sm cursor-pointer flex justify-center">
                                                    <ButtonLoader isLoading={true} />
                                                </p>
                                            </div>
                                        )}

                                        { !(isLoadingAdditionalData || isFetchingAdditionalData) && notesIds?.map((noteId: number) => {
                                            const note = additionalData?.data?.notes[noteId].note;
                                            // const note = (additionalData?.data?.notes as Record<number, { note: string }>)[noteId].note;

                                            return (
                                                <div key={noteId} className="mt-1 p-1 bg-yellow-100 rounded">
                                                    <p className="text-xs lg:text-sm cursor-pointer">{note}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}

                                { hasDocuments && (
                                    <div>
                                        <div className="mt-2">Document{ documentsIds.length > 1 ? 's' : ''}: </div>

                                        { (isLoadingAdditionalData || isFetchingAdditionalData) && (
                                            <div className="mt-1 p-1 bg-yellow-100 rounded">
                                                <p className="text-xs lg:text-sm cursor-pointer flex justify-center">
                                                    <ButtonLoader isLoading={true} />
                                                </p>
                                            </div>
                                        )}

                                        { !(isLoadingAdditionalData || isFetchingAdditionalData) && documentsIds?.map((documentId: number) => {
                                            const document = additionalData?.data?.documents[documentId]?.title;

                                            return (
                                                <div key={documentId}>
                                                    <p onClick={() => onClickDocument({
                                                        documentBatchData: additionalData?.data?.documents[documentId],
                                                        userId: crewMemberData?.[0].id ? String(crewMemberData?.[0].id) : ""
                                                    })} className="text-xs lg:text-sm text-primary underline cursor-pointer">{document}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        ) : null }
                    </Tooltip.Content>
                </Tooltip.Portal>
                </Tooltip.Root>
        </Tooltip.Provider>
    );
}
