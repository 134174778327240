import { apiSlice } from "@/app/api/apiSlice";

export const documentsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCrewMemberDocuments: builder.query({
			query: ({ vesselId, userId }) => `/api/vessels/${vesselId}/users/${userId}/documents`,
			providesTags: ["UserDocuments"],
		}),
		addCrewMemberDocument: builder.mutation({
			query: ({ vesselId, userId, data }) => ({
				url: `/api/vessels/${vesselId}/users/${userId}/documents`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["UserDocuments"],
		}),
		addCalendarDocument: builder.mutation({
			query: ({ vesselId, userId, data }) => ({
				url: `/api/vessels/${vesselId}/users/${userId}/gantt-documents`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["UserDocuments"],
		}),
		updateCrewMemberDocument: builder.mutation({
			query: ({ vesselId, userId, documentId, data }) => ({
				url: `/api/vessels/${vesselId}/users/${userId}/documents/${documentId}`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["UserDocuments", "PositionData"],
		}),
		removeCrewMemberDocument: builder.mutation({
			query: ({ vesselId, userId, documentId }) => ({
				url: `/api/vessels/${vesselId}/users/${userId}/documents/${documentId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["UserDocuments", "PositionData"],
		}),

		getCrewMemberDocumentsRelatedWithCalendar: builder.query({
			query: ({ vesselId, userId, startDate, endDate, contractId }) => `/api/vessels/${vesselId}/users/${userId}/documents?start_date=${startDate}&end_date=${endDate}&contract_id=${contractId}`,
			providesTags: ["UserDocuments"],
		})
	}),
	overrideExisting: true,
});

export const {
	useGetCrewMemberDocumentsQuery,
	useAddCrewMemberDocumentMutation,
	useUpdateCrewMemberDocumentMutation,
	useRemoveCrewMemberDocumentMutation,
	useAddCalendarDocumentMutation,
	useGetCrewMemberDocumentsRelatedWithCalendarQuery,
} = documentsApiSlice;
