import { useState } from "react";
import { FileChartColumn, ArrowRightToLine } from "lucide-react";

import { Button } from "@/components/ui/button";
import { WhiteBox } from "@/components/WhiteBox";
import { cn } from "@/lib/utils";
import { VesselDepartmentsStatisticsAccordion } from "@/components/accordions/VesselDepartmentsStatisticsAccordion";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { type StringOpenStates } from "@/types/AccordionOpenStates";
import { useGetStatisticsByDepartmentQuery } from "@/app/api/slices/shipCalendarApiSlice";

import { ButtonLoader } from "@/components/ButtonLoader";


interface Props {
    date: string | undefined | null;
    positionIds: number[] | string[] | undefined | null;

    minimizable?: boolean;
    isMinimized: boolean;
    onMaximize: () => void;
    onMinimalize: () => void;
}

export const StatisticsByDepartment = ({
    date,
    positionIds,

    minimizable = false,
    isMinimized,
    onMaximize,
    onMinimalize,
} : Props) => {
	const vessel_id = useCurrentVesselId();

    const [openStates, setOpenStates] = useState<StringOpenStates>({ "dep-0": true });
    const toggleOpenState = (sectionId: string) => {
		setOpenStates((prevStates) => ({
			...prevStates,
			[sectionId]: !prevStates[sectionId],
		}));
	};

    const { 
		data: myVesselResponse, 
		isLoading: isLoadingStatistics,
        isFetching: isFetchingStatistics
	} = useGetStatisticsByDepartmentQuery({ 
            vesselId: vessel_id, 
            positionIds,
            date
        },
        { skip: !vessel_id || !date || !positionIds}
    );

	return (
		<>
            {minimizable && isMinimized && (
                <WhiteBox 
                    className={cn("mt-3 cursor-pointer relative ms-4 p-1 relative flex flex-col justify-center items-center")}
                >
                    <Button variant={'ghost'} size="icon" onClick={onMaximize}>
                        <FileChartColumn />
                    </Button>
                    
                </WhiteBox>
            )}

            { !isMinimized && (
                <WhiteBox id="vessel-departments-statistics" className="mt-3 ms-4">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-1">
                            <FileChartColumn size="20"/>
                            <p className="font-bold text-nowrap">Statistics by department</p>
                        </div>

                        { minimizable && (
                            <div className="flex justify-end">
                                <Button variant={'ghost'} size="icon" className="h-auto p-1" onClick={onMinimalize}>
                                    <ArrowRightToLine />
                                </Button>
                            </div>
                        )}
                    </div>

                    <hr className="my-1 mb-2" />

                    {(isLoadingStatistics || isFetchingStatistics) && (
                        <div className="flex justify-center py-3">
                            <ButtonLoader isLoading={true}/>
                        </div>
                    )}
                  
                    { ((!isLoadingStatistics || !isFetchingStatistics) && !myVesselResponse) && (
                        <div className="flex justify-center py-3">
                            {!date && (<p className="text-center text-sm">Select a day in the calendar</p>)}
                            {date && (<p className="text-center text-sm">No data available</p>)}
                        </div>
                    )}

                    { ((!isLoadingStatistics && !isFetchingStatistics) && myVesselResponse) && (
                        <VesselDepartmentsStatisticsAccordion
                            openStates={openStates}
                            toggleOpenState={toggleOpenState}
                            departments={myVesselResponse?.data?.perDepartmentStats}
                            summary={myVesselResponse?.data?.summary}
                        />
                    )}


                </WhiteBox>
            )}
		</>
	);
};
