import { useEffect } from "react";

import {
	useGetPublicSuggestionsQuery,
	useGetPublishedSuggestionsQuery,
} from "@/app/api/slices/manager/managerSuggestionsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { AddSuggestionDialog } from "@/components/dialogs/AddSuggestionDialog";
import { ManagerSuggestionsPublishedTable } from "@/components/tables/ManagerSuggestionsPublishedTable";
import { ManagerSuggestionsTable } from "@/components/tables/ManagerSuggestionsTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useJoyride } from "@/contexts/JoyrideContext";
import { joyride_redirect_9_index } from "@/hocs/JoyrideWrapper";

export const ManagerSuggestionsPage = () => {
	const { data: suggestionsResponse, isLoading, isFetching } = useGetPublicSuggestionsQuery({});
	const {
		data: publishedSuggestionsResponse,
		isLoading: isLoadingPublished,
		isFetching: isFetchingPublished,
	} = useGetPublishedSuggestionsQuery({});

	const { run, startJoyride, stepIndex } = useJoyride();
	useEffect(() => {
		if (!run && !isLoading && stepIndex === joyride_redirect_9_index) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [isLoading]);

	if (isLoading || isFetching || isLoadingPublished || isFetchingPublished)
		return (
			<div id="manager-suggestions-page">
				<InLayoutLoader />
			</div>
		);

	return (
		<Tabs id="manager-suggestions-page" defaultValue="public">
			<TabsList id="knowledge-base-switcher">
				<TabsTrigger value="public">Public</TabsTrigger>
				<TabsTrigger value="published">Published</TabsTrigger>
			</TabsList>
			<TabsContent value="public">
				<WhiteBox id="manager-suggestions-page" className="mt-4">
					<div className="mb-4 flex flex-wrap items-end justify-between gap-3 sm:flex-nowrap">
						<div>
							<h1 className="mb-2 text-xl font-semibold md:text-2xl">Suggestions</h1>
							<p>You can vote for suggestions</p>
						</div>
						<AddSuggestionDialog />
					</div>
					<ManagerSuggestionsTable data={suggestionsResponse?.data} />
				</WhiteBox>
			</TabsContent>
			<TabsContent value="published">
				<WhiteBox className="mt-4">
					<div className="mb-4 flex flex-wrap items-end justify-between gap-3 sm:flex-nowrap">
						<div>
							<h1 className="mb-2 text-xl font-semibold md:text-2xl">Published Suggestions</h1>
							<p>Review your published suggestions</p>
						</div>
						<AddSuggestionDialog />
					</div>
					<ManagerSuggestionsPublishedTable data={publishedSuggestionsResponse?.data} />
				</WhiteBox>
			</TabsContent>
		</Tabs>
	);

	return (
		<WhiteBox id="manager-suggestions-page">
			<div className="mb-4 flex flex-wrap items-end justify-between gap-3 sm:flex-nowrap">
				<div>
					<h1 className="mb-2 text-xl font-semibold md:text-2xl">Suggestions</h1>
					<p>You can vote for suggestions</p>
				</div>
				<AddSuggestionDialog />
			</div>
			<ManagerSuggestionsTable data={suggestionsResponse?.data} />
		</WhiteBox>
	);
};
