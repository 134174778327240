import { type ReactNode } from "react";
import { cn } from "@/lib/utils";


type CalendarRowLabelProps = {
    label?: ReactNode, 
    className?: string,
    isClickable?: boolean,
    onClick?: () => void;
};

export const CalendarRowLabel = ({ 
    label, 
    className="bg-slate-100",

    isClickable=false,
    onClick=()=>{}
}: CalendarRowLabelProps) => {
    return (
        <div 
            className={cn(`mb-2 px-3 h-12 border rounded-md flex justify-center items-center text-center text-sm font-medium`, className, isClickable ? 'cursor-pointer' : '')}
            onClick={onClick}
        >
            {label}
        </div>
    );
}
