import { skeletonLoaderData, calendarSizesClasses } from "./settings";
import { cn } from "@/lib/utils";
import { CalendarCell } from "./CalendarCell";

type CalendarCellsSkeletonLoaderProps = {
    isLoading: boolean;
    calendarZoomLevel: number;
    calendarMonthData: {
        days: number;
        month: number;
        year: number;
        name: string | undefined;
    }
};

export const CalendarCellsSkeletonLoader = ({
    isLoading,
    calendarZoomLevel,
    calendarMonthData,
}: CalendarCellsSkeletonLoaderProps) => {
    return (
        <>
            { isLoading && (skeletonLoaderData.map( (department, index) => (
                <div className="grow" key={index}>
                
                    <div className="h-3.5"></div>
                    <hr className="pb-1"/>

                    { department.positions.map( (position, index) => (
                        <div className="grow" key={index}>
                                { position.crew.map( (_, index) => 
                                    <div key={index} className={cn("mb-2 flex", calendarSizesClasses[calendarZoomLevel].gap)}>
                                        {[...Array(calendarMonthData.days)].map((_, index) => (
                                            <CalendarCell 
                                                key={index}
                                                label={(
                                                    <div className="flex flex-col justify-center items-center">
                                                        <div>{""}</div>
                                                        <div className="text-xs">{""}</div>
                                                    </div>
                                                )}
                                                className={'bg-slate-100 animate-pulse'}
                                            />
                                        ))}
                                    </div>
                                )}
                        </div>
                    ))}

                </div>
            )))}
        </>
    );
}
