/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import axios from "axios";
import { format as formatFns } from "date-fns";

import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { Button } from "@/components/ui/button";
import { WhiteBox } from "@/components/WhiteBox";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Calendar as CalendarIcon, HandCoins, ArrowRightToLine } from "lucide-react";
import { cn } from "@/lib/utils";
import { SelectYear } from "./SelectYear";

import { useDateFormatter } from "@/hooks/useDateFormatter";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { useCurrentVessel } from "@/hooks/useCurrentVessel";
import { createFileName } from "@/utils/fileNameCreator";

const BASE_URL = import.meta.env.VITE_API_URL;

interface Props {
    minimizable?: boolean;
    isMinimized: boolean;
    onMaximize: () => void;
    onMinimalize: () => void;
}

export const Payrolls = ({
    minimizable = false,
    isMinimized,
    onMaximize,
    onMinimalize,
} : Props) => {
    const { format } = useDateFormatter();

    const [startDate, setStartDate] = useState<string | undefined | null>();
	const [endDate, setEndDate] = useState<string | undefined | null>();

    const [isDownloadingReport, setIsDownloadingReport] = useState(false);

    const { toast } = useToast();
	const vessel_id = useCurrentVesselId();
    const vesselData = useCurrentVessel();
	
	const downloadFileFromServer = async ({
		vessel_id,
		start_date,
		end_date,
        vessel_data
	}: {
		vessel_id: string;
		start_date: string;
		end_date: string;
        vessel_data: {
            name: string;
        } | undefined
	}) => {
		setIsDownloadingReport(true);
        try {
            const response = await axios.request({
                method: "GET",
                url: `${BASE_URL}/api/vessels/${vessel_id}/payroll?start_date=${formatFns(start_date, "y-MM-dd")}&end_date=${formatFns(end_date, "y-MM-dd")}`,
                responseType: "blob",
                withCredentials: true,
            });

            if (response.data) {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = href;

                const fileName = createFileName({
                    prefix: vessel_data?.name || '',
                    fileName: "payroll",
                    fileDates: [format(start_date), format(end_date)],
                    fileExtension: "xlsx",
                });
                
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        }
        catch(error: any) {
            const errorResponse = error;
            handleServerErrors({
                data: {
                    message: errorResponse.message,
                    errors: null
                }
            }, toast);
        }
        finally {
		    setIsDownloadingReport(false);
        }
	};

	const downloadReportHandler = () => {
		if (!startDate || !endDate || !vessel_id) return;
		downloadFileFromServer({
			vessel_id: String(vessel_id),
			start_date: startDate,
			end_date: endDate,
            vessel_data: vesselData
		});
	};

    useEffect(() => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        setStartDate(formatFns(firstDay, "y-MM-dd"));
        setEndDate(formatFns(lastDay, "y-MM-dd"));
    }, [])

    const [showYearDialog, setShowYearDialog] = useState(false);

    const showYearDialogHandler = () => {
		setShowYearDialog(true);
	}

	const hideYearDialogHandler = () => {
		setShowYearDialog(false);
	}

	return (
		<>
            <SelectYear 
				show={showYearDialog}
				onHide={hideYearDialogHandler}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>

            {minimizable && isMinimized && (
                <WhiteBox 
                    className={cn("cursor-pointer relative ms-4 p-1 relative flex flex-col justify-center items-center mt-3")}
                >
                    <Button variant={'ghost'} size="icon" onClick={onMaximize}>
                        <HandCoins />
                    </Button>
                    
                </WhiteBox>
            )}

            { !isMinimized && (
                <WhiteBox className={cn("relative ms-4 relative mt-3")}>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-1">
                            <HandCoins size="20"/>
                            <p className="font-bold text-nowrap">Payroll report</p>
                        </div>
                        { minimizable && (
                            <div className="flex justify-end">
                                <Button variant={'ghost'} size="icon" className="h-auto p-1" onClick={onMinimalize}>
                                    <ArrowRightToLine />
                                </Button>
                            </div>
                        )}
                    </div>
                    <hr className="my-1 mb-2" />

                    <div className="mb-2">
                        <Button
                            variant={"outline"}
                            className={cn(
                                "w-full justify-start text-left font-normal",
                                !startDate && !endDate && "text-muted-foreground",
                            )}
                            onClick={showYearDialogHandler}
                        >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {startDate && endDate ? (
                                <>
                                    {format(startDate)} - {format(endDate)}
                                </>
                            ) : (
                                <span>Select date</span>
                            )}
                        </Button>
                    </div>

                    <Button
                        className="mt-3 w-full"
                        disabled={isDownloadingReport}
                        onClick={downloadReportHandler}
                    >
                        <ButtonLoader isLoading={isDownloadingReport} />
                        Download All Crew Report
                    </Button>
                </WhiteBox>
            )}
		</>
	);
};
