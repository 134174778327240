/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { lastDayOfMonth, format as formatFns } from "date-fns";

import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "@/components/ui/button";
import { WhiteBox } from "@/components/WhiteBox";
import { Textarea } from "@/components/ui/textarea";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Calendar as CalendarIcon, ChevronsUpDown, Loader, MoveVertical } from "lucide-react";
import { type OpenStates } from "@/types/AccordionOpenStates";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { type DateRange } from "react-day-picker";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
	useGetDepartmentPositionsQuery,
	useGetCrewMemberStatisticsQuery
} from "@/app/api/slices/positionsApiSlice";
import { useGetDepartmentsAndPositionsQuery } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";

import { type ContractsInterface } from "@/types/Calendar";
import { Statistics } from "@/components/calendar/Controls/Statistics";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { useCurrentVessel } from "@/hooks/useCurrentVessel";
import { createFileName } from "@/utils/fileNameCreator";
import { formatName } from "@/utils/calendarUtils";

const BASE_URL = import.meta.env.VITE_API_URL;

interface Props {
	isMobile: boolean;
    setPage: React.Dispatch<React.SetStateAction<number | undefined>>;
    setSelectedContractId: React.Dispatch<React.SetStateAction<string | number | undefined>>;
    setSelectedPositionId: React.Dispatch<React.SetStateAction<string | number | undefined>>;
    selectedContractId: string | number | undefined;
    selectedPositionId: string | number | undefined;
    departmentId: string | undefined;
    setDepartmentId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const Controls = ({
	isMobile,
    setPage,
    setSelectedContractId,
    setSelectedPositionId,
    selectedPositionId,
    departmentId,
    setDepartmentId,
} : Props) => {
    const { format } = useDateFormatter();
    const { toast } = useToast();
	const auth = useAuth();
	const vessel_id = useCurrentVesselId();
    const vesselData = useCurrentVessel();

    const leaveBalaceType = auth?.user?.vessel?.leave_balance_type;

    const [searchParams, setSearchParams] = useSearchParams();
    const sp_Department = searchParams.get("department");
    const sp_Positions = searchParams.get("positions");
    const sp_CrewMemberId = searchParams.get("crew_member");

	const [selectedCrewMemberId, setSelectedCrewMemberId] = useState<string | number | undefined>();
	const [remarks, setRemarks] = useState<string | undefined>();
    
    const currentMonth = useMemo(() => {
        const currentDate = new Date();
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = lastDayOfMonth(currentDate);
        return { firstDay, lastDay };
    }, []);

    const [range, setRange] = useState<DateRange | undefined>({ from: currentMonth.firstDay, to: currentMonth.lastDay });

    const setDepartmentIdHandler = (departmentId: string) => {
        setDepartmentId(departmentId);
        setSelectedContractId(undefined);
        setSelectedPositionId(undefined);
        setPage(undefined);
        setSelectedCrewMemberId(undefined);

        setSearchParams({
            department: departmentId,
            positions: "",
            crew_member: "",
        });
    }

	const { data: departmentsResponse, isLoading: isLoadingDepartments } = useGetDepartmentsAndPositionsQuery(
        { vesselId: vessel_id }, 
        { skip: !vessel_id }
    );
    useEffect(() => {
        // select first department is available
        if(departmentsResponse?.data?.length) {
            const departmentId = (Number.isNaN(Number(sp_Department)) || (sp_Department === "") || !sp_Department) ? departmentsResponse?.data[0].id : sp_Department;

            setDepartmentId(String(departmentId));
            setSearchParams((prev) => ({
                    department: String(departmentId),
                    positions: prev.get("positions") || "",
                    crew_member: prev.get("crew_member") || "",
                })
            );
        }
    }, [departmentsResponse]);

    useEffect(() => {
        if(!vessel_id) return;
        setSearchParams({
            department: "",
            positions: "",
            crew_member: "",
        });
    }, [vessel_id])
    

    const handleCrewMemberSelection = (
        crewMemberId: number,
        positionId: number,
        contract: ContractsInterface,
    ) => {
        setSelectedCrewMemberId(crewMemberId);
        setSelectedPositionId(positionId);
        setSearchParams((prev) => ({
            department: prev.get("department") || "",
            positions: String(positionId),
            crew_member: String(crewMemberId),
        }));

        if (!contract) {
            setSelectedContractId(undefined);
            return;
        }
    
        setSelectedContractId(contract.id);
        setPage(contract.current_page || 1);
    };

	const { data: positionsResponse, isLoading: isLoadingPositions, isFetching: isFetchingPositions } = useGetDepartmentPositionsQuery(
		{ vessel_id: vessel_id, department_id: departmentId },
		{ skip: !vessel_id || !departmentId },
	);

    useEffect(() => {
        // select first position and first contract/crew member if available
        if(positionsResponse?.data?.positions?.length) {
            const defalutPositionId = positionsResponse?.data?.positions[0]?.id;

            const positionId = (Number.isNaN(Number(sp_Positions)) || (sp_Positions === "") || !sp_Positions) ? defalutPositionId : sp_Positions;
            
            const positionData = positionsResponse?.data?.positions.find((position: {id: number}) => String(position.id) === String(positionId));

            if(positionData) {
                setSelectedPositionId(positionId);
                toggleOpenState(positionId)

                const defaultContractId = positionData?.contracts[0];
                const defaultCrewMemberId = positionData?.contracts[0]?.crew[0]?.id;

                const contractDataWithIdOfCrewMemberFromSearchParam = positionData?.contracts.find((contract: {crew: any[]}) => {
                    return contract.crew.find((crewMember: {id: number}) => String(crewMember.id) === sp_CrewMemberId);
                });

                const contract = contractDataWithIdOfCrewMemberFromSearchParam || defaultContractId;
                const crewMemberId = contractDataWithIdOfCrewMemberFromSearchParam ? sp_CrewMemberId  : defaultCrewMemberId;

                if(crewMemberId && positionId && contract) {
                    setSearchParams((prev) => ({
                        department: prev.get("department") || "",
                        positions: String(positionId),
                        crew_member: String(crewMemberId),
                    }));
                
                    handleCrewMemberSelection(
                        Number(crewMemberId),
                        Number(positionId),
                        contract
                    );
                }
            }
        }
    }, [positionsResponse]);

	const formatDateForStats = (date: Date | undefined) => {
		if(!date) return;
		return formatFns(date, "y-MM-dd");
	}

	const { 
		data: statisticsResponse, 
		isLoading: isLoadingStatistics
	} = useGetCrewMemberStatisticsQuery({ vessel_id, user_id: selectedCrewMemberId, startDate: formatDateForStats(range?.from), endDate: formatDateForStats(range?.to) }, { skip: !vessel_id || !selectedCrewMemberId || !range?.from || !range?.to});

	const [openStates, setOpenStates] = useState<OpenStates>({});
	const toggleOpenState = (sectionId: number) => {
        setOpenStates((prevStates) => {
            if(Object.keys(prevStates).length === 0 && prevStates.constructor === Object) return {[sectionId]: true};

            const newState: OpenStates = Object.entries(prevStates).reduce((acc, [key]) => {
                return {
                    ...acc,
                    [key]: false
                }
            }, {})
            newState[sectionId] = !prevStates[sectionId];

            return newState;
        });
        setSelectedCrewMemberId(undefined);
        // setSearchParams((prev) => ({
        //     department: prev.get("department") || "",
        //     positions: String(sectionId),
        //     crew_member: "",
        // }));
	};

	const [isDownloadingReport, setIsDownloadingReport] = useState(false);

	const downloadFileFromServer = async ({
		vessel_id,
		user_id,
		start_date,
		end_date,
        vessel_data,
        first_name,
        last_name,
	}: {
		vessel_id: string;
		user_id: string;
		start_date: Date;
		end_date: Date;
        vessel_data: {
            name: string;
        } | undefined;
        first_name: string;
        last_name: string;
	}) => {
		setIsDownloadingReport(true);
        try {
            const response = await axios.request({
                method: "POST",
                url: `${BASE_URL}/api/vessels/${vessel_id}/users/${user_id}/report?start_date=${formatFns(start_date, "y-MM-dd")}&end_date=${formatFns(end_date, "y-MM-dd")}`,
                data: {
                    remarks,
                },
                responseType: "blob",
                withCredentials: true,
            });

            if (response.data) {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = href;

                const fileName = createFileName({
                    prefix: vessel_data?.name || '',
                    fileName: `report${formatName({firstName: first_name, lastName: last_name})}`,
                    fileDates: [format(start_date), format(end_date)],
                    fileExtension: "pdf",
                });

                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setRemarks("");
            }
        }
        catch(error: any) {
            const errorResponse = error;
            handleServerErrors({
                data: {
                    message: errorResponse.message,
                    errors: null
                }
            }, toast);
        }
        finally {
		    setIsDownloadingReport(false);
        }
	};

	const downloadReportHandler = () => {
		if (!selectedCrewMemberId || !range || !range.from || !range.to || !vessel_id) return;
		downloadFileFromServer({
			vessel_id: String(vessel_id),
			user_id: String(selectedCrewMemberId),
			start_date: range.from,
			end_date: range.to,
            vessel_data: vesselData,
            first_name: statisticsResponse?.data?.first_name || "",
            last_name: statisticsResponse?.data?.last_name  || "",
		});
	};

	return (
		<>
            <WhiteBox id={isMobile ? "" : "position-calendar-department-select"} className={cn("relative mb-4 me-4", isMobile && "border")}>
                <div>
                    <div>
                        <span className="font-bold">Department</span>
                    </div>
                    <hr className="my-1 mb-2" />
                    <Select
                        onValueChange={setDepartmentIdHandler}
                        value={departmentId}
                        disabled={isLoadingDepartments || isLoadingPositions}
                    >
                        <SelectTrigger className="relative">
                            <SelectValue placeholder="Select department..." />
                            {isLoadingDepartments && (
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <ButtonLoader isLoading={true} />
                                </div>
                            )}
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {departmentsResponse?.data?.map((department: { id: number; name: string }) => (
                                    <SelectItem key={department.id} value={String(department.id)}>
                                        {department.name}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </WhiteBox>

            <WhiteBox id={isMobile ? "" : "position-calendar-position-select"} className={cn("relative mb-4 me-4", isMobile && "border")}>
                <div className="flex flex-col gap-3">
                    {!positionsResponse && !isLoadingPositions && (
                        <div className="flex grow items-center justify-center">
                            <span className="text-sm">Select a department</span>
                        </div>
                    )}

                    {(isLoadingPositions || isFetchingPositions) && (
                        <div className="flex grow items-center justify-center">
                            <Loader className="animate-spin duration-2000" color="#000" />
                        </div>
                    )}

                    {(positionsResponse && !isLoadingPositions && !isFetchingPositions) &&
						positionsResponse?.data?.positions.map(
							(position: { id: number; contracts: ContractsInterface[]; name: string }) => {
								return (
									<Collapsible
										key={position.id}
										open={openStates[position.id] || false}
										onOpenChange={() => toggleOpenState(position.id)}
										className="space-y-2"
									>
										<div className="flex items-center justify-between space-x-4">
											<CollapsibleTrigger asChild>
												<Button
													variant="ghost"
													size="sm"
													className={cn(
														"flex w-full items-center justify-between border",
														position.id === selectedPositionId
															? "bg-slate-100"
															: "",
													)}
												>
													<h4 className="text-sm font-semibold">{position.name}</h4>
													<ChevronsUpDown className="h-4 w-4" />
													<span className="sr-only">Toggle</span>
												</Button>
											</CollapsibleTrigger>
										</div>
										<CollapsibleContent className="space-y-2 ps-3">
											{(!position?.contracts || position?.contracts?.length < 1) && (
												<div className="text-center text-sm">
													No leave plan created for this position
												</div>
											)}

											{position?.contracts?.map((contract: ContractsInterface) => {
												return (
													<div key={`contract-${contract.id}`} className="relative flex flex-col gap-1">
														{contract?.crew?.length > 1 && (
															<div className="absolute -right-1 top-1/2 -translate-y-1/2">
																<MoveVertical color="#6b7280" />
															</div>
														)}
														{contract?.crew?.map((crewMember) => {
															return (
																<Button
																	key={crewMember.id}
																	variant="ghost"
																	size="sm"
																	className={cn(
																		"flex w-full items-center justify-between border",
																		crewMember.id === selectedCrewMemberId ? "bg-slate-100" : "",
																	)}
																	onClick={() =>
																		handleCrewMemberSelection(crewMember.id, position.id, contract)
																	}
																>
																	<span className="text-sm">{crewMember.name}</span>
																</Button>
															);
														})}
													</div>
												);
											})}
										</CollapsibleContent>
									</Collapsible>
								);
							},
						)}
                </div>
            </WhiteBox>

            <WhiteBox id={isMobile ? "" : "position-calendar-report"} className={cn("relative me-4", isMobile && "border")}>
                <div>
                    <span className="font-bold">Statistics for selected crew member</span>
                </div>
                <hr className="my-1 mb-2" />

                {!selectedCrewMemberId && (
                    <div className="mb-2 text-center text-sm">Select a crew member</div>
                )}

                {selectedCrewMemberId && (
                    <>
                        <div className="mb-2">
                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-full justify-start text-left font-normal",
                                            !range && "text-muted-foreground",
                                        )}
                                    >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {range && range.from && range.to ? (
                                            <>
                                                {format(range.from)} - {format(range.to)}
                                            </>
                                        ) : (
                                            <span>Select date</span>
                                        )}
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-auto p-0">
                                    <Calendar 
                                        mode="range" 
                                        selected={range} 
                                        onSelect={setRange}
                                        defaultMonth={range?.from || new Date()}
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                    </>
                )}

                { (isLoadingStatistics) && (
                    <div className="flex justify-center items-center grow mb-3" >
                        <Loader className="animate-spin duration-2000" color="#000" />
                    </div>
                )}

                { (!isLoadingStatistics && selectedCrewMemberId && range?.from && range?.to) && (
                    <div className="flex flex-col mb-3">
                        <Statistics 
                            type="positions"
                            number_of_rotations={statisticsResponse?.data?.estimated_number_of_rotations}
                            number_of_travel_days={statisticsResponse?.data?.estimated_number_of_travel_days}
                            number_of_days_onboard={statisticsResponse?.data?.estimated_number_of_days_onboard}
                            number_of_days_leave={statisticsResponse?.data?.estimated_number_of_days_leave}
                            rotationPeriodType={statisticsResponse?.data?.rotationPeriodType || ""}
                            leaveBalaceType={leaveBalaceType}
                            leave_balance_1_onboard={statisticsResponse?.data?.leave_balance_1_onboard}
                            leave_balance_1_offboard={statisticsResponse?.data?.leave_balance_1_offboard}
                            leave_balance_2={statisticsResponse?.data?.leave_balance_2}
                            leaveAllowance={statisticsResponse?.data?.leaveAllowance}
                            leaveAllowanceBalance={statisticsResponse?.data?.leaveAllowanceBalance}
                        />
                    </div>
                )}

                <div>
                    <Textarea
                        className="resize-none"
                        placeholder="Remarks"
                        disabled={!selectedCrewMemberId}
                        onChange={(e) => setRemarks(e.target.value)}
                        value={remarks}
                    />
                </div>

                <Button
                    className="mt-3 w-full"
                    disabled={!selectedCrewMemberId || isDownloadingReport}
                    onClick={downloadReportHandler}
                >
                    <ButtonLoader isLoading={isDownloadingReport} />
                    Download Report
                </Button>
            </WhiteBox>
		</>
	);
};
